<template>
  <q-page class="checkoutPage">
    <div class="page-content">
      <div class="container">
        <template v-if="checkoutSteps && checkoutSteps.length">
          <div class="page-title hide-in-mobile">
            <div v-if="!isMobile">
              <Alert
                v-if="cartAlert"
                dismissible
                :variant="cartAlert.variant"
                @dismissed="
                  () => {
                    this.alertShow = 0
                  }
                "
                @dismiss-count-down="
                  (countDown) => {
                    this.alertShow = countDown
                  }
                "
                :show="alertShow"
              >
                <span v-html="cartAlert.message" />
              </Alert>
            </div>
            <h1>
              <template v-if="!isReviewOrder"> Checkout </template>
              <template v-else> Review Order </template>
            </h1>
          </div>
          <div class="sectionInner">
            <div class="sectionLeft">
              <q-stepper
                header-nav
                class="checkoutStep"
                v-model="step"
                :vertical="!isMobile"
                :alternative-labels="isMobile"
                active-color="primary"
                animated
              >
                <!-- :vertical="!isMobile" -->
                <q-step
                  v-for="(checkoutStep, index) in checkoutSteps"
                  :key="`${checkoutStep.id}-${index}`"
                  :name="isReviewOrder ? 'review' : index + 1"
                  :caption="
                    !isMobile ? `${index + 1} of ${checkoutSteps.length}` : ``
                  "
                  :title="!isMobile ? `${checkoutStep.title}` : ''"
                  :header-nav="step > index + 1"
                  :icon="`img:images/checkout/${checkoutStep.id}.svg`"
                  :active-icon="`img:images/checkout/${checkoutStep.id}.svg`"
                >
                  <template
                    v-if="
                      checkoutStep.id == 'od' &&
                      selectedFulfillmentCodeName == 'scd' &&
                      currentCartDetails.fulfillments[0]?.deliveryMethod == 1
                    "
                  >
                    <p
                      v-if="
                        currentCartDetails.fulfillments[0]
                          ?.isSignatureRequired &&
                        currentCartDetails.fulfillments[0]?.isAgeRestricted
                      "
                      class="alert-warning r-4 q-pa-sm q-mt-sm text-body2 checkout-alert"
                      :class="isMobile ? 'q-mx-sm' : ''"
                    >
                      You are purchasing
                      <strong>age-restricted</strong> products so the delivery
                      person may ask to verify your <strong>age.</strong> Your
                      <strong>signature</strong> is required as proof of the
                      order delivery.
                    </p>
                    <p
                      v-else-if="
                        currentCartDetails.fulfillments[0]?.isSignatureRequired
                      "
                      class="alert-warning r-4 q-pa-sm q-mt-sm text-body2 checkout-alert"
                      :class="isMobile ? 'q-mx-sm' : ''"
                    >
                      Your <strong>signature</strong> is required as a part of
                      the proof that the order was delivered
                    </p>
                    <p
                      v-else-if="
                        currentCartDetails.fulfillments[0]?.isAgeRestricted
                      "
                      class="alert-warning r-4 q-pa-sm q-mt-sm text-body2 checkout-alert"
                      :class="isMobile ? 'q-mx-sm' : ''"
                    >
                      You are purchasing the
                      <strong>age-restricted</strong> product and the delivery
                      person may verify your <strong>age</strong> during
                      delivery.
                    </p>
                    <p v-else></p>
                  </template>

                  <OrderDetails
                    :currentCartDetails="currentCartDetails"
                    v-if="checkoutStep.id == 'od'"
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :title="checkoutStep.title"
                  />

                  <PersonalInfo
                    v-if="checkoutStep.id == 'pi'"
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :cartNumber="currentCartNumber"
                    :fulfillmentObject="checkoutStep.fulfillmentObject"
                    :title="checkoutStep.title"
                  />

                  <VerifyAge
                    v-if="checkoutStep.id == 'age-verify'"
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :fulfillmentObject="checkoutStep.fulfillmentObject"
                    :title="checkoutStep.title"
                  />

                  <AddressList
                    v-if="checkoutStep.id == 'scd' || checkoutStep.id == 'sd'"
                    :addressType="checkoutStep.id"
                    :fulfillmentId="
                      checkoutStep.fulfillmentObject.fulfillmentMethodID
                    "
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :title="checkoutStep.title"
                  />

                  <ShippingMethod
                    v-if="checkoutStep.id == 'sm'"
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :cartNumber="currentCartNumber"
                    :fulfillmentObject="checkoutStep.fulfillmentObject"
                    :title="checkoutStep.title"
                  />

                  <PickUpinfo
                    v-if="checkoutStep.id == 'sp' || checkoutStep.id == 'di'"
                    :next="next"
                    :isReviewOrder="isReviewOrder"
                    :cartNumber="currentCartNumber"
                    :fulfillmentObject="checkoutStep.fulfillmentObject"
                    :title="checkoutStep.title"
                  />

                  <PaymentList
                    v-if="checkoutStep.id == 'pbi'"
                    :isReviewOrder="isReviewOrder"
                    :stepNo="index"
                    :next="reviewOrder"
                    :title="checkoutStep.title"
                  />
                </q-step>
              </q-stepper>

              <q-stepper-navigation
                class="text-center q-pt-lg"
                v-if="step == 'review' && !isMobile"
              >
                <div class="form-action flex justify-center">
                  <!-- <div class="row">
                    <div class="col-auto">
                      <q-btn
                        v-if="!isMobile"
                        :to="{ name: 'Cart' }"
                        unelevated
                        no-caps
                        rounded
                        outline
                        color="tertiary"
                        label="Back to Cart"
                        class="q-btn-lg q-px-xl radius-lg"
                      />
                      <br />
                      <q-btn
                        v-if="!isMobile"
                        to="/"
                        unelevated
                        no-caps
                        rounded
                        flat
                        color="tertiary"
                        label="Continue Shopping"
                        class="q-btn-lg q-px-sm q-ml-sm text-underline no-hover radius-lg"
                      />
                    </div>
                  </div> -->
                  <div>
                    <q-btn
                      unelevated
                      no-caps
                      :rounded="!isMobile"
                      @click="proceedCheckout"
                      color="secondaryOnBody"
                      label="Place Order"
                      :size="isMobile ? '' : '1.1rem'"
                      :class="[
                        'q-btn-lg',
                        isMobile ? 'full-width' : 'q-px-xl radius-lg',
                      ]"
                      :disable="isDisableCheckout"
                    />
                  </div>
                </div>
              </q-stepper-navigation>
            </div>
            <OrderSummary
              :currentCartDetails="currentCartDetails"
              :currentCardId="currentCartNumber"
              cancelCheckoutBtnUrl="Cart"
              cancelCheckoutBtnName="Back to Cart"
              checkoutBtnName="Place Order"
              :showAdditionalText="true"
              :proceedCheckout="proceedCheckout"
              :disableCheckout="isDisableCheckout"
              v-show="!isMobile || step == checkoutSteps.length"
            />
          </div>

          <AddPaymentMethod />
          <AddBillingAddress />
          <TipConfirmDialog @checkoutOrder="checkoutOrder" />
        </template>
        <EmptyComponent
          image="images/empty-cart.png"
          btnText="Continue Shopping"
          to="/"
          v-else-if="!loading"
        >
          <p>Your cart is currently empty.</p>
        </EmptyComponent>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  validateMinCheckoutAmount,
  cartHasFulfillment,
  hideShowFullSpinner,
} from 'src/utils'
import OrderSummary from 'components/cart/OrderSummary'
import OrderDetails from 'components/checkout/OrderDetails'
import AddressList from 'components/checkout/AddressList'
import PickUpinfo from 'components/checkout/PickUpinfo'
import PaymentList from 'components/checkout/PaymentList'
import AddPaymentMethod from 'components/payment/AddPaymentMethod'
import AddBillingAddress from 'components/payment/AddBillingAddress'
import ShippingMethod from 'components/checkout/ShippingMethod'
import { NotifyMethods } from 'src/boot/notify'
import TipConfirmDialog from 'src/components/cart/TipConfirmDialog.vue'
import PersonalInfo from 'components/checkout/PersonalInfo'
import VerifyAge from 'components/checkout/VerifyAge'
import { setAVSessionID } from 'src/boot/axios'

export default {
  name: 'PageCheckout',
  /* async preFetch({ store, redirect }) {
    await store.dispatch('cart/getCartDetail')

    let cartDetail = store.getters['cart/currentCartDetails'],
      customer = store.getters['customer/customer'],
      businessConfigurations = store.getters['common/businessConfigurations'],
      subscribeToLNS =
        store.getters['persisted/subscribeToLNS'] &&
        customer &&
        !customer.isLoyalty &&
        businessConfigurations.isLnsEnabled,
      isSlotBookingRequired = store.getters['common/checkSlotBookingRequired'],
      customerSPSlotId = store.getters['reserveSlot/customerSPSlotId'],
      customerSCDSlotId = store.getters['reserveSlot/customerSCDSlotId'],
      isValid = validateMinCheckoutAmount(cartDetail).valid,
      checkoutInViewMode = true,
      eaivPersistedSessionId =
        store.getters['persisted/eaivPersistedSessionId'],
      eaivPersistedVerificationSuccess =
        store.getters['common/eaivPersistedVerificationSuccess'],
      isOrderVerified = store.getters['ageVerification/isOrderVerified'],
      isLoggedIn = store.getters['auth/isLoggedIn']

    // redirect to cart if user's age not verified
    if (
      businessConfigurations?.isStoreAgeRestricted &&
      businessConfigurations?.enableAgeRestrictedCheckout &&
      cartDetail?.ageRequired > 0 &&
      !isOrderVerified
    ) {
      redirect({
        name: 'Cart',
      })
      NotifyMethods.showError('Verify your age to continue.')
    }
    // redirect to cart if user's age not verified

    if (cartHasFulfillment(cartDetail, 'sd')) {
      await store.dispatch('cart/getShippingRates', true)
    }

    if (
      isValid &&
      isSlotBookingRequired('sp') &&
      cartHasFulfillment(cartDetail, 'sp') &&
      !customerSPSlotId
    ) {
      isValid = false
    }

    if (
      isValid &&
      isSlotBookingRequired('scd') &&
      cartHasFulfillment(cartDetail, 'scd') &&
      !customerSCDSlotId
    ) {
      isValid = false
    }

    if (!isValid) {
      redirect({
        name: 'Cart',
      })
    }

    if (!cartDetail) return

    if (!isLoggedIn) {
      if (cartDetail.customerID)
        await store.dispatch('cart/fetchGuestUserInfo', cartDetail.customerID)
      else checkoutInViewMode = false
    }

    if (
      checkoutInViewMode &&
      businessConfigurations?.isStoreAgeRestricted &&
      businessConfigurations?.enableAgeRestrictedCheckout &&
      cartDetail?.ageRequired > 0 &&
      !isOrderVerified
    ) {
      checkoutInViewMode = false
    }

    // if (!isLoggedIn && !cartDetail.customerID) checkoutInViewMode = false
    // else if (!isLoggedIn && cartDetail.customerID)
    //   await store.dispatch('cart/fetchGuestUserInfo', cartDetail.customerID)

    if (!cartDetail.paymentMethodID && checkoutInViewMode) {
      checkoutInViewMode = false
    }
    // Set Order info
    let orderDetail = {
      orderNumber: cartDetail.orderNumber,
      orderFulfillments: [],
      // paymentMethodID: cartDetail.paymentMethodID,
      paymentMethodID:
        store.getters['cart/selectedPaymentMethodId'] ||
        cartDetail.paymentMethodID,
      subscribeToLNS,
    }
    if (!isLoggedIn && cartDetail.customerID)
      orderDetail.customerID = cartDetail.customerID

    // Set avSessionID for age verification
    if (
      businessConfigurations.isStoreAgeRestricted &&
      businessConfigurations.enableAgeRestrictedCheckout &&
      eaivPersistedVerificationSuccess &&
      eaivPersistedSessionId
    )
      orderDetail.avSessionID = eaivPersistedSessionId
    // Set avSessionID for age verification

    if (cartDetail?.fulfillments) {
      for (let fId in cartDetail.fulfillments) {
        const fulfillment = cartDetail.fulfillments[fId]
        if (checkoutInViewMode) {
          if (
            fulfillment.codeName == 'sp' &&
            (!fulfillment.pickupPerson ||
              (fulfillment.pickupPerson &&
                !fulfillment.pickupPerson.pickUpByEmail1))
          ) {
            checkoutInViewMode = false
          } else if (
            (fulfillment.codeName == 'sd' || fulfillment.codeName == 'scd') &&
            !fulfillment.addressID
          ) {
            checkoutInViewMode = false
          }
          let addresses = store.getters['address/addresses'],
            billingAddresses = store.getters['address/addresses']
          if (!isLoggedIn && cartDetail.customerID) {
            if (
              (fulfillment.codeName == 'sd' || fulfillment.codeName == 'scd') &&
              fulfillment.addressID &&
              !Object.values(addresses).length
            )
              store.dispatch('address/getCustomerAddresses', {
                customerID: cartDetail.customerID,
              })
            if (!Object.values(billingAddresses).length) {
              store.dispatch('address/getBillingAddresses', {
                customerID: cartDetail.customerID,
              })
            }
          }
        }
        let orderFulfillment = {
          fulfillmentMethodID: fulfillment.fulfillmentMethodID,
          addressID: fulfillment.addressID,
          pickupPerson: fulfillment.pickupPerson,
          isPayAtStore: false,
        }
        // SET_CURRENT_ORDER_DETAIL: update delivery addressID stored in persisted locally
        if (fulfillment.codeName == 'scd' || fulfillment.codeName == 'sd') {
          let addressDetail = store.getters[
            'persisted/selectedAddressForFulfillmentCode'
          ](fulfillment.codeName)
          addressDetail && addressDetail.addressId
            ? (orderFulfillment.addressID = addressDetail.addressId)
            : null
        }
        orderDetail.orderFulfillments.push(orderFulfillment)
      }
      orderDetail.isAcknowledgeAccepted =
        store.getters['cart/isAcknowledgeAccepted']
    }
    store.commit('cart/SET_CURRENT_ORDER_DETAIL', orderDetail)
    store.commit('cart/SET_CHECKOUT_IN_REVIEW_MODE', checkoutInViewMode)
  }, */
  computed: {
    ...mapGetters('cart', [
      'currentCartDetails',
      'currentCartNumber',
      'pickupInfoByFulfillmentId',
      'selectedPaymentMethod',
      'selectedPaymentMethodId',
      'checkoutInReviewMode',
      'currentOrderParameters',
      'loading',
      'isPayAtStoreAvailable',
      'allowPlaceOrder',
      'isTipConfirmed',
      'checkoutSelection',
    ]),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('persistedLocal', [
      'getCartPersistedInfo',
      'selectedAddressForFulfillmentCode',
      'subscribeToLNS',
      'isContactlessdelivery',
    ]),
    ...mapGetters('cart', ['alertData']),
    ...mapGetters('ageVerification', ['isOrderVerified']),
    ...mapGetters('guest', ['guestUser', 'guestCartNumber']),
    cartAlert() {
      return this.alertData('cartAlert')
    },
    ...mapGetters('common', ['businessFulfillments']),

    checkoutSteps() {
      let steps = []
      if (this.currentCartDetails?.fulfillments) {
        steps = [
          {
            title: 'Order Details',
            id: 'od',
          },
          {
            title: 'Payment and Billing Information',
            id: 'pbi',
          },
        ]

        for (const fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]

          steps.splice(steps.length - 1, 0, {
            title: 'Personal Information',
            id: 'pi',
            fulfillmentObject: fulfillment,
          })

          steps.splice(steps.length - 1, 0, {
            title: this.checkoutfulfillment[fulfillment.codeName],
            id: fulfillment.codeName,
            fulfillmentObject: fulfillment,
          })

          if (fulfillment.codeName == 'sd') {
            // this.cartHasStandardShipping = true
            steps.splice(steps.length - 1, 0, {
              title: 'Shipping Method',
              id: 'sm',
              fulfillmentObject: fulfillment,
            })
          } else if (fulfillment.codeName == 'scd') {
            // this.cartHasStandardScheduleDelivery = true
            steps.splice(steps.length - 1, 0, {
              title: 'Delivery Information',
              id: 'di',
              fulfillmentObject: fulfillment,
            })
          }

          if (
            this.businessConfigurations?.isStoreAgeRestricted &&
            this.businessConfigurations?.enableAgeRestrictedCheckout &&
            this.currentCartDetails?.ageRequired > 0
          ) {
            steps.splice(steps.length - 1, 0, {
              title: 'Verify Your Age',
              id: 'age-verify',
              fulfillmentObject: fulfillment,
            })
          }
        }
      }
      return steps
    },
    isDisableCheckout() {
      if (this.businessConfigurations.mode === 5) return true
      else if (!this.currentOrderParameters?.isAcknowledgeAccepted) return true
      else if (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.currentCartDetails?.ageRequired > 0 &&
        !this.isOrderVerified
      ) {
        return true
      } else if (!this.selectedPaymentMethodId) {
        return !this.selectedPaymentMethodId
      } else if (this.isMobile) {
        return (
          this.step != this.checkoutSteps.length ||
          (this.step == this.checkoutSteps.length && !this.allowPlaceOrder)
        )
      } else {
        return (
          !this.isReviewOrder || (this.isReviewOrder && !this.allowPlaceOrder)
        )
      }

      /* Previous Code */
      // return (this.isMobile ? this.step != this.checkoutSteps.length : !this.isReviewOrder) || !this.selectedPaymentMethodId
    },
    showTip() {
      const selectedFulfillment =
        this.businessFulfillments[this.selectedFulfillmentCodeName]
      if (
        this.$route.name == 'Checkout' &&
        this.selectedFulfillmentCodeName !== 'sd' &&
        selectedFulfillment.isTipEnabled
      ) {
        return true
      }
      return false
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
  },
  components: {
    OrderSummary,
    AddressList,
    OrderDetails,
    PickUpinfo,
    PaymentList,
    AddPaymentMethod,
    AddBillingAddress,
    ShippingMethod,
    TipConfirmDialog,
    PersonalInfo,
    VerifyAge,
  },
  meta() {
    return {
      title: 'Checkout',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      alertShow: 0,
      checkoutfulfillment: {
        sd: 'Shipping Address',
        scd: 'Delivery Address',
        sp: 'Store Pickup Information',
      },
      step: 1,
      isReviewOrder: false,
      // cartHasStandardShipping: false,
      // cartHasStandardScheduleDelivery: false,
      payAtStore: {
        // is: false,
        sp: false,
        scd: false,
        sd: false,
      },
      payAtStoreOrderParameters: {},
      tipOption: [],
    }
  },
  watch: {
    cartAlert() {
      this.alertShow = 10
    },
  },
  methods: {
    openReserSlot(bookingType) {
      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: true,
        properties: {
          activeTab: bookingType,
        },
      })
    },
    reviewOrder() {
      this.$root.$emit('scroll-to-position', 0)
      this.isReviewOrder = true
      this.step = 'review'
    },
    next() {
      this.step += 1
    },
    goToStep(step) {
      this.step = parseInt(step)
      this.isReviewOrder = false
    },
    async checkoutOrder() {
      let hasError = false
      hideShowFullSpinner(true, true)

      await this.$store.dispatch('common/getConfiguration')

      if (
        !this.isLoggedIn &&
        !this.businessConfigurations?.enableGuestCheckout
      ) {
        hasError = true
        this.showError(
          `Currently you can't place the order as a guest user. Sign up/sign in to place the order`
        )
      }

      this.recalculateOrderParameters()
      let params = Object.assign({}, this.currentOrderParameters)

      if (
        this.selectedFulfillmentCodeName == 'sd' &&
        !params['shippingRateID']
      ) {
        hasError = true
        this.showError(`Please Select Shipping Method`)
      }

      if (hasError) {
        hideShowFullSpinner(false)
        return false
      }

      // set paymentMethodID empty for is or SP when Multi fulfillment with pay at store
      if (
        // cartHasFulfillment(this.currentCartDetails, 'sp') &&
        // !cartHasFulfillment(this.currentCartDetails, 'scd') &&
        // !cartHasFulfillment(this.currentCartDetails, 'sd') &&
        this.selectedFulfillmentCodeName == 'sp' &&
        this.isPayAtStoreAvailable('sp')
      ) {
        params.paymentMethodID = '00000000-0000-0000-0000-000000000000'
      }
      if (this.currentCartDetails.orderSummary.tipAmount) {
        params.tipAmount = this.currentCartDetails.orderSummary.tipAmount
      }

      params.IsContactlessDropOff = this.isContactlessdelivery
      /*
      let cartPersistedInfo = this.getCartPersistedInfo(this.currentCartNumber)

      if (this.selectedFulfillmentCodeName == 'sd') {
        params['shippingRateID'] = cartPersistedInfo?.rateID || ''
        params['deliveryInstruction'] =
          cartPersistedInfo?.deliveryInstruction || ''
      } else if (this.selectedFulfillmentCodeName == 'sp') {
        params['pickupType'] = cartPersistedInfo?.pickupType || 1
      }*/

      this.$store
        .dispatch('cart/placeOrder', params)
        .then(async (response) => {
          if (response.couponErrors && response.couponErrors.length)
            this.showMultipleCouponsError(
              this.formatErrorMessage(response.couponErrors)
            )
          if (response && response.orderNumber) {
            if (this.isLoggedIn) {
              this.$store.commit('persistedLocal/REMOVE_SUBSCRIBE_TO_LNS')
              this.$store.commit(
                'persistedLocal/REMOVE_CONTACTLESS_DELIVERY',
                false
              )
              this.$store.commit('cart/SET_TIP_CONFIRM', false)
              try {
                const promises = [
                  this.$store.dispatch('reserveSlot/getCustomerBlockedSlots'),
                  this.$store.dispatch('customer/getCustomer'),
                  this.$store.dispatch('customer/getTotalRedeemableDollars'),
                ]
                Promise.all(promises).then(() => {})
              } catch (err) {
                console.log('err', err)
              }
            }

            this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
              cartNumber: response.orderNumber,
            })

            this.$router.push(
              {
                name: 'OrderComplete',
                params: { orderNumber: response.orderNumber },
              },
              () => {
                hideShowFullSpinner(false)
                this.$store.dispatch('cart/resetCart')
              }
            )
          } else if (
            response &&
            response.errors &&
            response.errors.length > 0
          ) {
            // fulfillment validation
            response.errors.forEach((error) => {
              if (error.errorCode == 'CRT_400') {
                this.$store.dispatch('common/getConfiguration').finally(() => {
                  const title =
                    'The fulfillment method is unavailable for your selected location (store) currently. How would you like to place your order? '
                  this.changeDialogState({
                    dialog: 'changeLocation',
                    val: true,
                    properties: {
                      dialogTitle: title,
                    },
                  })
                })
              }
            })

            if (
              response.errors[0].errorCode &&
              response.errors[0].errorCode === 'CRT_0009'
            ) {
              this.changeDialogState({
                dialog: 'UpdateProductQuantityDialog',
                val: true,
                properties: {
                  loading: true,
                },
              })

              await this.$store
                .dispatch('cart/getOutOfStockProducts', {
                  locationID: this.currentLocationId,
                  orderNumber: this.currentCartNumber,
                })
                .then(() => {
                  this.$store.commit('showcase/SET_DIALOG_PROPERTIES', {
                    dialog: 'UpdateProductQuantityDialog',
                    properties: {
                      loading: false,
                    },
                  })
                })
                .catch((err) => console.log(err))
            } else {
              this.$store.dispatch('cart/showAlert', response.errors)
              this.$root.$emit('scroll-to-position')
            }
          } else {
            this.$store.dispatch('cart/showAlert', [
              {
                descreption: 'something went wrong please try again later.',
              },
            ])
            this.$root.$emit('scroll-to-position')
          }
          hideShowFullSpinner(false)
        })
        .catch((error) => {
          this.$store.dispatch('cart/showAlert', [
            {
              descreption: 'something went wrong please try again later.',
            },
          ])
          this.$root.$emit('scroll-to-position')
          hideShowFullSpinner(false)
        })
    },
    async proceedCheckout() {
      if (
        !this.currentCartDetails.orderSummary.tipAmount &&
        this.showTip &&
        !this.isTipConfirmed
      ) {
        this.changeDialogState({
          dialog: 'TipConfirmDialog',
          val: true,
        })
      } else this.checkoutOrder()
      this.$store.commit('cart/SET_TIP_CONFIRM', true)
    },

    formatErrorMessage(messages) {
      let result = `<span>`
      for (const message of messages) {
        result += `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-negative q-ma-xs">error</i>${message}<br />`
      }
      result += `</span>`
      return result
    },
    recalculateOrderParameters() {
      let cartPersistedInfo = this.getCartPersistedInfo(this.currentCartNumber)
      let cartDetail = this.currentCartDetails,
        subscribeToLNS =
          this.subscribeToLNS &&
          this.customer &&
          !this.customer.isLoyalty &&
          this.businessConfigurations.isLnsEnabled
      let orderDetail = {
        orderNumber: cartDetail.orderNumber,
        orderFulfillments: [],
        paymentMethodID:
          this.selectedPaymentMethodId || cartDetail.paymentMethodID,
        subscribeToLNS,
      }
      if (!this.isLoggedIn && cartDetail.customerID)
        orderDetail.customerID = cartDetail.customerID

      /* Set avSessionID for age verification */
      if (
        this.businessConfigurations.isStoreAgeRestricted &&
        this.businessConfigurations.enableAgeRestrictedCheckout &&
        this.eaivPersistedVerificationSuccess &&
        this.eaivPersistedSessionId
      )
        orderDetail.avSessionID = this.eaivPersistedSessionId
      /* Set avSessionID for age verification */

      for (let fId in cartDetail.fulfillments) {
        const fulfillment = cartDetail.fulfillments[fId]
        let orderFulfillment = {
          fulfillmentMethodID: fulfillment.fulfillmentMethodID,
          addressID: fulfillment.addressID,
          pickupPerson: this.pickupInfoByFulfillmentId(
            fulfillment.fulfillmentMethodID
          ),
          isPayAtStore: this.isPayAtStoreAvailable(fulfillment.codeName),
        }
        // SET_CURRENT_ORDER_DETAIL: update delivery addressID stored in persisted locally
        if (fulfillment.codeName == 'scd' || fulfillment.codeName == 'sd') {
          let addressDetail = this.selectedAddressForFulfillmentCode(
            fulfillment.codeName
          )
          addressDetail && addressDetail.addressId
            ? (orderFulfillment.addressID = addressDetail.addressId)
            : null
        }

        orderFulfillment['deliveryInstruction'] =
          cartPersistedInfo?.deliveryInstruction || ''
        // Set shippingRateID, deliveryInstruction and pickupType param while place an order

        if (this.selectedFulfillmentCodeName == 'sd') {
          orderDetail['shippingRateID'] =
            this.checkoutSelection?.rateID || fulfillment.shippingRateID || ''
        }

        if (cartPersistedInfo && this.selectedFulfillmentCodeName == 'sp') {
          orderFulfillment['pickupType'] = cartPersistedInfo?.pickupType || 1
        }

        orderDetail.orderFulfillments.push(orderFulfillment)
      }
      orderDetail.isAcknowledgeAccepted =
        this.currentOrderParameters.isAcknowledgeAccepted

      this.$store.commit('cart/SET_CURRENT_ORDER_DETAIL', orderDetail)
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    async onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Checkout') {
        try {
          if (!eaivSessionId || !routeName) return
          if (!this.isLoggedIn && this.guestUser?.customerID) {
            // get updated guest user info after verification
            await this.$store.dispatch(
              'cart/fetchGuestUserInfo',
              this.guestUser.customerID
            )
            // get updated pickup info & prefill pickup person data at checkout step after verification
            if (!this.isLoggedIn && this.selectedFulfillmentCodeName != 'sd')
              await this.$store.dispatch('cart/getCartDetail', {
                showFullLoader: true,
                isCheckoutPage: true,
              })
          }

          if (!this.currentCartNumber) {
            await this.$store.dispatch('cart/getCartDetail', {
              showFullLoader: true,
              isCheckoutPage: true,
            })
          }

          this.$store.commit(
            'ageVerification/SET_CURRENT_CART_NUMBER',
            this.currentCartNumber
          )
          // if (isPopup) tempCacheStorage.clear()
          // else this.removeQueryParams(eaivSessionId, true)
          if (!isPopup) this.removeQueryParams(eaivSessionId, true)

          setTimeout(() => {
            this.$store.commit('ageVerification/SET_LOADING', false)
            this.showSuccess('Age Verified Successfully.')
          }, 500)
        } catch (error) {
          console.log('error', error)
        }
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Checkout') {
        if (!eaivSessionId || !routeName) return
        // if (isPopup) tempCacheStorage.clear()
        // else this.removeQueryParams(eaivSessionId, false)
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          let properties = {
            redirectUri: this.getRedirectUri,
            verificationDuring: 1,
            customerID: null,
            routeName,
          }
          if (!this.isLoggedIn && this.guestUser?.customerID) {
            properties.customerID = this.guestUser.customerID
            properties.preFillInformation = {
              firstName: this.guestUser.firstName || '',
              lastName: this.guestUser.lastName || '',
              phone: this.guestUser.phoneNumber || '',
              email: this.guestUser.email || '',
              countryCode: this.guestUser.countryCode || '+1',
              dob: this.guestUser.dob
                ? this.$options.filters.formatDate(
                    this.guestUser.dob.split('T')[0],
                    'YYYY-MM-DD'
                  )
                : undefined,
            }
          }

          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties,
          })
        }, 500)
      }
    },
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)

    /* if (this.checkoutInReviewMode && !this.isMobile) {
      this.reviewOrder()
    } */

    this.$store.commit('common/SET_HEADER_TITLE', {
      title: 'Checkout',
      params: {
        accountButton: false,
        cartButton: false,
        homeButtom: this.isMobile,
      },
    })

    let params = {}
    if (!this.isLoggedIn && this.currentCartDetails?.customerID)
      params.customerID = this.currentCartDetails.customerID
    this.$store.dispatch('payment/getAvailableMethods', params)
    // check tip option
    // if (this.selectedFulfillmentCodeName !== 'sp') {
    //   const selectedFulfillment =
    //     this.businessFulfillments[this.selectedFulfillmentCodeName]
    //   if (selectedFulfillment.isTipEnabled) {
    //     this.isTipEnabled = true
    //   }
    // }

    // Calculate Delivery Rates and disable checkout when any error occurs
    if (this.selectedFulfillmentCodeName == 'scd') {
      this.$store.dispatch('cart/getDeliveryRates').then((deliveryRateRes) => {
        let errors = deliveryRateRes && deliveryRateRes?.errors?.length
        this.$store.commit('cart/SET_ALLOW_PLACE_ORDER', !errors)
        if (deliveryRateRes && deliveryRateRes?.errors?.length)
          this.$store.dispatch('cart/showAlert', deliveryRateRes.errors)
      })
    }

    if (
      !this.isLoggedIn &&
      !this.guestUser &&
      this.businessConfigurations?.isStoreAgeRestricted &&
      this.businessConfigurations?.enableAgeRestrictedCheckout &&
      this.eaivPersistedSessionId &&
      this.isOrderVerified
    ) {
      try {
        hideShowFullSpinner(true)
        this.$store
          .dispatch('ageVerification/checkStatus', this.eaivPersistedSessionId)
          .then((response) => {
            if (response.success && response.data) {
              if (
                response.data?.isVerified === true &&
                response.data?.personalInfo
              ) {
                let personalInfo = response.data.personalInfo
                let userInfo = {
                  firstName: personalInfo.firstName,
                  lastName: personalInfo.lastName,
                }
                if (personalInfo.email) userInfo.email = personalInfo.email
                else
                  userInfo.phoneNumber =
                    personalInfo.countryCode + ' ' + personalInfo.phone

                this.$store.commit('guest/SET_GUEST_USER', userInfo)
              }
            }
          })
          .finally(() => hideShowFullSpinner(false))
      } catch (error) {
        console.log('error', error)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('cart/SET_CHECKOUT_SELECTION')
  },
  destroyed() {
    // ToDo: Un used Code
    // this.$store.commit('common/COUNT_DOWN_CHANGE', 0)

    if (this.cartAlert) {
      this.$store.commit('cart/SET_ALERT', {
        alertKey: 'cartAlert',
        alertData: null,
      })
      this.$store.commit('cart/SET_ALLOW_PLACE_ORDER', true)
    }

    this.$root.$off('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$off('eaivVerificationFailed', this.onEaivFail)
  },
  async mounted() {
    await this.$store.dispatch('cart/getCartDetail', {
      showFullLoader: true,
      isCheckoutPage: true,
    })

    let cartDetail = this.currentCartDetails,
      customer = this.$store.getters['customer/customer'],
      businessConfigurations =
        this.$store.getters['common/businessConfigurations'],
      subscribeToLNS =
        this.$store.getters['persistedLocal/subscribeToLNS'] &&
        customer &&
        !customer.isLoyalty &&
        businessConfigurations.isLnsEnabled,
      isSlotBookingRequired =
        this.$store.getters['common/checkSlotBookingRequired'],
      customerSPSlotId = this.$store.getters['reserveSlot/customerSPSlotId'],
      customerSCDSlotId = this.$store.getters['reserveSlot/customerSCDSlotId'],
      isValid = validateMinCheckoutAmount(cartDetail).valid,
      checkoutInViewMode = true,
      eaivPersistedSessionId =
        this.$store.getters['persisted/eaivPersistedSessionId'],
      eaivPersistedVerificationSuccess =
        this.$store.getters['common/eaivPersistedVerificationSuccess'],
      isOrderVerified = this.$store.getters['ageVerification/isOrderVerified'],
      isLoggedIn = this.$store.getters['auth/isLoggedIn']

    // redirect to cart if user's age not verified
    /* if (
      businessConfigurations?.isStoreAgeRestricted &&
      businessConfigurations?.enableAgeRestrictedCheckout &&
      cartDetail?.ageRequired > 0 &&
      !isOrderVerified
    ) {
      this.$router.replace({
        name: 'Cart',
      })
      return NotifyMethods.showError('Verify your age to continue.')
    } */
    // redirect to cart if user's age not verified

    if (cartHasFulfillment(cartDetail, 'sd')) {
      await this.$store.dispatch('cart/getShippingRates', true)
    }

    if (
      isValid &&
      isSlotBookingRequired('sp') &&
      cartHasFulfillment(cartDetail, 'sp') &&
      !customerSPSlotId
    ) {
      isValid = false
    }

    if (
      isValid &&
      isSlotBookingRequired('scd') &&
      cartHasFulfillment(cartDetail, 'scd') &&
      !customerSCDSlotId
    ) {
      isValid = false
    }

    if (!isValid) {
      this.$router.replace({
        name: 'Cart',
      })
    }

    if (!cartDetail) return

    if (!this.isLoggedIn) {
      if (cartDetail.customerID)
        await this.$store.dispatch(
          'cart/fetchGuestUserInfo',
          cartDetail.customerID
        )
      else checkoutInViewMode = false
    }

    if (
      checkoutInViewMode &&
      businessConfigurations?.isStoreAgeRestricted &&
      businessConfigurations?.enableAgeRestrictedCheckout &&
      cartDetail?.ageRequired > 0 &&
      !isOrderVerified
    ) {
      checkoutInViewMode = false
    }

    // if (!isLoggedIn && !cartDetail.customerID) checkoutInViewMode = false
    // else if (!isLoggedIn && cartDetail.customerID)
    //   await store.dispatch('cart/fetchGuestUserInfo', cartDetail.customerID)

    /* if (!isLoggedIn && !cartDetail.customerID) checkoutInViewMode = false
    else if (!isLoggedIn && cartDetail.customerID)
      await this.$store.dispatch(
        'cart/fetchGuestUserInfo',
        cartDetail.customerID
      ) */

    if (!cartDetail.paymentMethodID && checkoutInViewMode) {
      checkoutInViewMode = false
    }
    // Set Order info
    let orderDetail = {
      orderNumber: cartDetail.orderNumber,
      orderFulfillments: [],
      // paymentMethodID: cartDetail.paymentMethodID,
      paymentMethodID:
        this.$store.getters['cart/selectedPaymentMethodId'] ||
        cartDetail.paymentMethodID,
      subscribeToLNS,
    }
    if (!isLoggedIn && cartDetail.customerID)
      orderDetail.customerID = cartDetail.customerID

    // Set avSessionID for age verification
    if (
      businessConfigurations.isStoreAgeRestricted &&
      businessConfigurations.enableAgeRestrictedCheckout &&
      eaivPersistedVerificationSuccess &&
      eaivPersistedSessionId
    )
      orderDetail.avSessionID = eaivPersistedSessionId
    // Set avSessionID for age verification

    for (let fId in cartDetail.fulfillments) {
      const fulfillment = cartDetail.fulfillments[fId]
      if (checkoutInViewMode) {
        if (
          fulfillment.codeName == 'sp' &&
          (!fulfillment.pickupPerson ||
            (fulfillment.pickupPerson &&
              !fulfillment.pickupPerson.pickUpByEmail1))
        ) {
          checkoutInViewMode = false
        } else if (
          (fulfillment.codeName == 'sd' || fulfillment.codeName == 'scd') &&
          !fulfillment.addressID
        ) {
          checkoutInViewMode = false
        }

        let addresses = this.$store.getters['address/addresses'],
          billingAddresses = this.$store.getters['address/addresses']
        if (!isLoggedIn && cartDetail.customerID) {
          if (
            (fulfillment.codeName == 'sd' || fulfillment.codeName == 'scd') &&
            fulfillment.addressID &&
            !Object.values(addresses).length
          )
            this.$store.dispatch('address/getCustomerAddresses', {
              customerID: cartDetail.customerID,
            })
          if (!Object.values(billingAddresses).length) {
            this.$store.dispatch('address/getBillingAddresses', {
              customerID: cartDetail.customerID,
            })
          }
        }
      }

      let orderFulfillment = {
        fulfillmentMethodID: fulfillment.fulfillmentMethodID,
        addressID: fulfillment.addressID,
        pickupPerson: fulfillment.pickupPerson,
        isPayAtStore: false,
      }
      // SET_CURRENT_ORDER_DETAIL: update delivery addressID stored in persisted locally
      if (fulfillment.codeName == 'scd' || fulfillment.codeName == 'sd') {
        let addressDetail = this.$store.getters[
          'persistedLocal/selectedAddressForFulfillmentCode'
        ](fulfillment.codeName)
        addressDetail && addressDetail.addressId
          ? (orderFulfillment.addressID = addressDetail.addressId)
          : null
      }

      orderDetail.orderFulfillments.push(orderFulfillment)
    }

    this.$store.commit('cart/SET_CURRENT_ORDER_DETAIL', orderDetail)
    this.$store.commit('cart/SET_CHECKOUT_IN_REVIEW_MODE', checkoutInViewMode)

    if (checkoutInViewMode && !this.isMobile) this.reviewOrder()
  },
}
</script>
<style lang="scss">
.checkoutPage {
  .page-title {
    margin-bottom: 10px;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: normal;
      color: #0f141a;
      span {
        margin-left: 5px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        color: #888888;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
        span {
          font-size: 18px;
        }
      }
      @media (max-width: 599px) {
        span {
          display: block;
        }
      }
    }
  }
  /* Content */
  .page-content {
    padding: 0 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
    .form-field,
    .form-action {
      padding-bottom: 16px;
      &.pb-0,
      &.pb-none {
        padding-bottom: 0;
      }
    }
    .form-checkbox {
      font-size: 13px;
      color: #0f141a;
    }
  }
  .sectionInner {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    .sectionLeft {
      flex: 10000 0 0%;
      padding: 0 15px;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    .sectionRight {
      width: 360px;
      padding: 0 15px;
      @media (max-width: 1023px) {
        width: 100%;
        padding: 0;
      }
    }
  }
  .orderSubscription {
    background: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 20px;
    p {
      margin: 0;
      padding: 12px 15px;
      font-size: 12px;
      color: #888888;
    }
    .q-item {
      padding: 12px;
      background: #fff;
      border-bottom: 1px solid #cccccc;
      border-radius: 5px;
      .q-item__section--avatar {
        min-width: 50px;
        padding-right: 0;
      }
      .q-item__section--main {
        padding-top: 10px;
        padding-bottom: 5px;
      }
      .q-item__label {
        font-size: 16px;
        font-weight: bold;
        color: #0f141a;
        padding-bottom: 5px;
        &.text-caption {
          font-size: 13px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 0;
      margin-bottom: 0;
      border: none;
      .q-item {
        padding: 5px;
        border: none;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
    .q-stepper__nav {
      margin: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 15px;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      .col-12 {
        padding: 0;
      }
      .form-action {
        padding: 0;
      }
    }
  }
}

.checkoutStep {
  padding: 0;
  box-shadow: none;
  .q-stepper__step {
    padding: 20px 0 10px;
    @media (max-width: 599px) {
      padding: 0;
    }
  }
  .q-stepper__tab {
    min-height: 52px;
    padding: 10px;
    background: #fafafa;
    border: 1px solid transparent;
    border-radius: 5px;
    .q-stepper__label {
      flex: 10000 0 0%;
      display: flex;
      align-self: center;
      .q-stepper__title {
        flex: 10000 0 0%;
        order: 1;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 500;
        color: #888888;
        border-left: 1px solid #888888;
        @media (max-width: 599px) {
          padding: 0 10px;
          font-size: 14px;
        }
        @media (min-width: 1440px) {
          font-size: 18px;
        }
      }
      .q-stepper__caption {
        min-width: 60px;
        padding: 2px 5px;
        font-size: 12px;
        color: #888888;
        text-align: center;
        @media (max-width: 599px) {
          min-width: 50px;
        }
      }
    }
    .q-stepper__dot {
      display: none;
    }
    &.q-stepper__tab--active {
      background: #fff;
      border-color: $primaryOnBody;
      border-color: var(--q-color-primaryOnBody) !important;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      .q-stepper__title {
        color: $primaryOnBody;
        color: var(--q-color-primaryOnBody) !important;
        font-weight: 600;
      }
      .q-stepper__caption {
        color: $tertiary;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 30px;
        bottom: -16px;
        border: 8px solid transparent;
        border-top: 8px solid #fff;
      }
      &:after {
        left: 30px;
        bottom: -15px;
        border-width: 7px solid transparent;
        border-top-color: #fff;
      }
      &:before {
        border-top-color: $primaryOnBody;
        border-top-color: var(--q-color-primaryOnBody) !important;
      }
    }
  }
  &.q-stepper--vertical {
    .q-stepper__dot:before,
    .q-stepper__dot:after {
      content: none;
    }
  }

  .q-stepper__step-inner {
    padding: 30px 10px 0;
    position: relative;

    .step-edit {
      position: absolute;
      right: 20px;
      top: -35px;
      font-size: 13px;
    }
  }

  /* Tabbing */
  &.q-stepper--horizontal {
    flex-direction: row;
    flex-wrap: nowrap;
    .q-stepper__header {
      justify-content: center;
      background: #f5f5f5;
    }
    .q-stepper__tab {
      padding: 15px 5px;
      background: none;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      border: none;
      box-shadow: none;
      .q-stepper__dot {
        width: 36px;
        height: 36px;
        line-height: 36px;
        .q-icon {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
    .q-stepper__label {
      display: none;
      flex-direction: column;
      .q-stepper__caption {
        order: 1;
      }
      .q-stepper__title {
        order: 2;
        border: none;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .q-stepper__dot {
      display: flex;
    }
    .q-stepper__tab--active {
    }
    .q-stepper__step-inner {
      padding: 5px 0 5px;
      // height: calc(100vh - 145px);
      overflow-x: hidden;
      overflow-y: auto;
      & > div {
        /* padding-bottom: 80px; */
      }
      .step-edit {
        right: 15px;
        top: 20px;
      }
    }
    .stepsTitle {
      margin: 0 0 15px;
      padding: 10px 0 10px;
      font-size: 18px;
      line-height: 30px;
      font-weight: bold;
      color: $primary;
      color: var(--q-color-primary) !important;
      text-align: center;
      border-bottom: 1px solid #d7d7d7;
    }
  }
}

.orderItems {
  padding-top: 20px;
  &:first-child {
    padding: 0;
  }
}

/* Step Shipping Address */
.stepsAddress {
  .q-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    .q-item {
      width: 280px;
      padding: 10px;
      @media (max-width: 599px) {
        width: 100%;
      }
      & > .q-item__section {
        justify-content: flex-start;
        position: relative;
        background: #fff;
        border: 1px solid #cccccc;
        padding: 10px 5px 15px;
        /* padding-bottom: 50px; */
        font-size: 16px;
        line-height: 24px;
        color: #888888;
        height: 100%;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        .q-radio__inner {
          & ~ .q-radio__label {
            font-size: 16px;
            font-weight: normal;
            color: #888888;
          }
          &.q-radio__inner--truthy {
            & ~ .q-radio__label {
              font-weight: bold;
              color: $primaryOnBody;
              color: var(--q-color-primaryOnBody) !important;
            }
          }
        }
        .q-item__label {
          padding-left: 8px;
          padding-right: 8px;
          span.default {
            display: inline-block;
            padding: 3px 15px;
            margin-left: 5px;
            background: #91bf5b;
            font-size: 12px;
            color: #fff;
            border-radius: 10px;
          }
        }
        a.text-tertiary {
          position: absolute;
          left: 14px;
          bottom: 14px;
          font-size: 14px;
        }
        &.addAddress {
          padding: 50px;
          background: $themelight;
          background: var(--q-color-light-primary);
          border-color: $primary;
          border-color: var(--q-color-primary) !important;
          font-size: 14px;
          font-weight: bold;
          color: $tertiary;
          text-align: center;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 48px;
            line-height: 48px;
            display: block;
          }
        }
        .editAddress {
          background: #acacac;
          color: #fff;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          position: absolute;
          right: 8px;
          top: 8px;
          text-decoration: none;
        }
        &.invalidcard {
          background: rgba(193, 0, 1, 0.05);
          border-color: rgba(193, 0, 1, 0.2);
          .invalidcard-icon {
            z-index: 1;
          }
        }
      }

      &:hover,
      &.active {
        .q-item__section {
          border-color: $primaryOnBody;
          border-color: var(--q-color-primaryOnBody) !important;
          box-shadow: 0px 0px 0px 1px $primaryOnBody;
          box-shadow: 0px 0px 0px 1px var(--q-color-primaryOnBody) !important;
        }
      }

      .q-focus-helper,
      .q-ripple {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    .q-list {
      margin-left: -15px;
      margin-right: -15px;
      padding-top: 0;
      padding-bottom: 15px;
      .q-item {
        padding: 5px 15px;
        .q-checkbox {
          margin-left: -26px;
        }
        .q-radio {
          position: absolute;
          top: 8px;
          left: 8px;
          .q-radio__label {
            display: none;
          }
          & + .q-item__label {
            font-weight: bold;
            color: $tertiary;
          }
        }
        .paymentIcon {
          margin-right: 0px;
        }
        .text-edit {
          top: 10px;
          right: 12px;
          left: auto !important;
          bottom: auto !important;
        }
      }
      .q-item > .q-item__section {
        padding: 15px 40px;
        padding-right: 10px;
        &.addAddress {
          padding: 0;
          padding-left: 40px;
          // height: 54px;
          align-items: flex-start;
          font-size: 16px;
          font-weight: bold;
          text-transform: capitalize;
          span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            font-size: 30px;
            width: 50px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
/* Step Shipping Address */

/* Shipping Address */
.shippingAddress {
  padding: 0 0 40px;
  .dialogHeader {
    padding: 30px 0 15px;
    @media (min-width: 1681px) {
      padding-bottom: 30px;
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 599px) {
      padding: 20px 0;
      background: $primary;
      background: var(--q-color-primary) !important;
      color: $white;
      margin-bottom: 20px;
      h4.text-h5 {
        padding-right: 60px;
        font-size: 18px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
  .container {
    max-width: 1140px;
  }
  .form-field,
  .form-action {
    padding-bottom: 16px;
  }
  .form-checkbox {
    font-size: 13px;
    color: #0f141a;
  }
}
/* Shipping Address */

/* Steps Time Slot */
.stepsTimeSlot {
  h5 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    color: $tertiary;
    a {
      font-size: 13px;
      font-weight: normal;
    }
  }
  p {
    font-size: 16px;
    font-weight: normal;
    color: #888888;
  }
  @media (max-width: 1023px) {
    position: relative;
    padding-right: 70px;
    h5 {
      a {
        position: absolute;
        right: 0;
        font-size: 15px;
        font-size: 0px;
        font-weight: normal;
        line-height: 30px;
        width: 30px;
        height: 30px;
        text-align: center;
        background: #2a9dcc;
        color: #fff;
        border-radius: 5px;
        text-decoration: none;
        &:before {
          content: 'edit';
          font-size: 18px;
          font-family: 'Material Icons';
        }
      }
    }
  }
}
/* Steps Time Slot */

/* Schedule Address Dialog */
.scheduleList {
  .scheduleInner {
    border: 1px solid #dadada;
    border-radius: 10px;
  }
  .addressBreadcrumb {
    padding-bottom: 15px;
    a,
    span {
      padding-right: 20px;
      font-size: 16px;
      color: #888888;
      position: relative;
      text-decoration: none;
      i {
        margin-right: 10px;
        font-size: 12px;
        top: -1px;
        position: relative;
      }
    }
    span {
      &:before {
        content: '/';
        position: absolute;
        left: -12px;
      }
    }
    a:hover {
      color: $primary;
      color: var(--q-color-primary) !important;
    }
  }
  .addressSelect {
    padding: 0;
    border-bottom: 1px solid #dadada;
    h6 {
      font-size: 16px;
      color: #0f141a;
      margin: 0;
      padding: 0;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
      a {
        font-size: 12px;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 22px;
      color: #888888;
    }
    div[class^='col'] {
      padding: 20px;
      &:not(.addbtn) {
        border-left: 1px solid #dadada;
      }
      &:first-child {
        border-left: none;
      }
    }
  }
  .addressDays {
    border-bottom: 1px solid #dadada;
    ul {
      margin: 0;
      padding: 10px 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      li {
        padding: 10px;
        font-size: 18px;
        text-align: center;
        a {
          display: block;
          color: #0f141a;
          text-decoration: none;
        }
        span {
          margin: 5px 0;
          display: block;
          width: 40px;
          height: 40px;
          font-size: 16px;
          line-height: 40px;
          color: #314154;
          border-radius: 50%;
        }
        &.current {
          font-weight: bold;
          span {
            color: #fff;
            background: $primary;
            background: var(--q-color-primary) !important;
            font-weight: normal;
          }
        }
      }
    }
  }
  .addressTime {
    .q-item {
      border-bottom: 1px solid #dadada;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .searchAddress {
    padding: 25px 20px;
    .searchAddressList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      .q-item {
        padding: 10px;
        width: 100%;
        height: auto;
        @media (min-width: 600px) {
          width: 33.33%;
        }
        @media (min-width: 1024px) {
          width: 25%;
        }
        .q-item__section {
          justify-content: flex-start;
          position: relative;
          padding: 20px 20px;
          padding-bottom: 75px;
          height: 100%;
          background: #fff;
          border: 1px solid transparent;
          border-radius: 5px;
          .q-item__label {
            padding-bottom: 5px;
            font-size: 14px;
            line-height: 20px !important;
            color: #888888;
            &.title {
              font-size: 20px;
              color: #0f141a;
              span {
                display: inline-block;
                padding: 3px 10px;
                margin: 3px;
                font-size: 12px;
                line-height: normal;
                background: #91bf5b;
                color: #fff;
                border-radius: 12px;
              }
            }
          }
          &:hover {
            background: #f1fbff;
            border-color: #c4e7f5;
          }
          &.selected {
            background: #f1fbff;
            border-color: #c4e7f5;
          }
          .action {
            position: absolute;
            left: 20px;
            bottom: 25px;
          }
        }
      }
    }
  }
}
@media (min-width: 1681px) {
}
@media (max-width: 599px) {
  .scheduleList {
    .addressDays ul {
      li {
        font-size: 13px;
        padding: 10px 1px;
        span {
          font-size: 12px;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
/* Schedule Address Dialog */

/* Order Pick Up Info */
.orderPickupInfo {
  font-size: 18px;
}
.orderPickupDefault {
  padding-top: 10px;
  .form-checkbox {
    font-size: 16px !important;
    color: #888888 !important;
  }
}
/* Order Pick Up Info */

/* Order Review Info */
.orderReviewInfo {
  h5,
  .h5 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
  }
  p,
  .p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
}
/* Order Review Info */
.platform-ios {
  .checkoutPage {
    .q-stepper__step-inner {
      // height: calc(100vh) !important;
      height: calc(100vh - 197px);
      overflow: hidden;
    }
  }
}
</style>
