<template>
  <div>
    <q-form
      ref="pickupInforForm"
      @submit="submitFormAndContinue"
      class="position-inherit"
    >
      <div :class="isMobile ? 'checkoutHeightxs' : ''">
        <a
          href="javascript:void(0)"
          :class="['text-tertiary step-edit', { hidden: !isViewMode }]"
          @click="editInfo()"
        >
          <SvgIcon icon="edit" class="q-mr-sm" />Edit
        </a>
        <template v-if="!isViewMode">
          <div class="row">
            <div class="col-xs-12 col-md-12 order-1" v-show="isMobile">
              <h2 class="stepsTitle" v-html="title" />
            </div>

            <div
              class="col-xs-12 col-md-4 order-2 order-md-3"
              v-if="fulfillmentObject.codeName == 'sp' || isSlotBookingRequired"
            >
              <div
                class="stepsTimeSlot q-mb-md"
                v-if="fulfillmentObject.codeName == 'sp'"
              >
                <h5>Pickup Method</h5>
                <q-list class="shipping-method-list">
                  <q-item dense tag="label" class="q-pa-sm r-8">
                    <q-item-section side>
                      <q-radio
                        dense
                        v-model="pickUpMethod"
                        :val="1"
                        color="primary"
                      />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">
                        In Store Pickup
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item dense tag="label" class="q-pa-sm r-8">
                    <q-item-section side>
                      <q-radio
                        dense
                        v-model="pickUpMethod"
                        :val="2"
                        color="primary"
                      />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-weight-bold">
                        Curbside Pickup
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
              <div
                class="stepsTimeSlot"
                v-if="
                  isSlotBookingRequired && fulfillmentObject.codeName == 'sp'
                "
              >
                <h5>
                  Time Slot
                  <a
                    href="javascript:void(0);"
                    class="text-tertiary"
                    @click="
                      openReserSlot(fulfillmentObject.codeName.toUpperCase())
                    "
                  >
                    Edit
                  </a>
                </h5>
                <p
                  v-if="
                    fulfillmentObject.codeName == 'sp' &&
                    formatedCustomerSPBookedSlot
                  "
                  v-html="formatedCustomerSPBookedSlot"
                />
              </div>
            </div>

            <div class="col-12 order-3 order-md-1">
              <p
                :class="['text-body1 text-dark', { 'text-justify': isMobile }]"
                v-show="fulfillmentObject.codeName == 'sp'"
              >
                We will send email notifications to keep you updated on the
                order status.
                <span v-if="isLoggedIn">
                  <br />Your <strong>identification</strong> will be required
                  when you come to pick up your order. If your order includes
                  any age-restricted items,
                  <strong>age verification</strong> will also be necessary. In
                  both cases, you must present a valid
                  <strong>driver's license</strong> to be scanned and the
                  <strong>confirmation code</strong> (provided in the email).
                </span>
                <span v-else>
                  Show your <strong>driver's license</strong> during the order
                  fulfillment.
                </span>
              </p>
              <p
                :class="['text-body1 text-dark', { 'text-justify': isMobile }]"
                v-show="fulfillmentObject.codeName == 'scd'"
              >
                We will send email notifications to keep you updated on the
                order status.
                <span v-if="isLoggedIn">
                  <br />Your <strong>identification</strong> will be required
                  when you receive your order. If your order includes any
                  age-restricted items, <strong>age verification</strong> will
                  also be necessary. In both cases, you must present a valid
                  <strong>driver's license</strong> to be scanned and the
                  <strong>confirmation code</strong> (provided in the email).
                </span>
                <span v-else>
                  Show your <strong>driver's license</strong> during the order
                  fulfillment.
                </span>
              </p>
            </div>

            <div class="col-xs-12 col-md-8 order-4 order-md-2">
              <!--<div class="orderPickupInfo">
                 <p class="text-tertiary">
                  We'll email you when your order is ready.
                </p> -->
              <!-- <p
                  class="text-primaryOnBody"
                  v-show="currentCartDetails?.ageRequired > 0"
                >
                  Bring a valid government ID such as driver's license or
                  passport.
                </p>
              </div>-->
              <div class="cs-review-info">
                <div class="row q-col-gutter-x-lg">
                  <div class="col-12">
                    <p class="text-dark text-weight-bold">
                      Primary Person (Myself)
                    </p>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model.trim="pickupPerson.primaryUserFirstName"
                        label="First Name"
                        placeholder="First Nane"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          this.validatePersonName(
                            pickupPerson.primaryUserFirstName,
                            'Please enter a valid name'
                          ),
                        ]"
                        :lazy-rules="lazyValidationRules"
                        :disable="true"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model.trim="pickupPerson.primaryUserLastName"
                        label="Last Name"
                        placeholder="Last Name"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          this.validatePersonName(
                            pickupPerson.primaryUserLastName,
                            'Please enter a valid name'
                          ),
                        ]"
                        :lazy-rules="lazyValidationRules"
                        :disable="true"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-4"></div>
                  <div
                    class="col-xs-12 col-sm-8"
                    :class="{
                      'col-md-4': selectedPickupByDOB1 && showDOBField,
                    }"
                  >
                    <div class="form-field">
                      <q-input
                        outlined
                        v-model="pickupPerson.pickUpByEmail1"
                        label="Email"
                        placeholder="Your Email"
                        dense
                        :rules="[
                          (val) => validateRequired(val),
                          (val) => validateEmail(val),
                        ]"
                        type="email"
                        :disable="true"
                      />
                    </div>
                    <!-- <q-input
                  outlined
                  v-model="pickUpByPhone1"
                  label="Get Texted too (optional)"
                  placeholder="Your phone number"
                  v-mask="phoneMask"
                  :rules="[this.validatePhone()]"
                  dense
                /> -->
                  </div>
                  <div
                    class="col-xs-12 col-sm-6 col-md-4"
                    v-if="selectedPickupByDOB1 && showDOBField"
                  >
                    <div class="form-field">
                      <q-input
                        outlined
                        dense
                        :value="selectedPickupByDOB1"
                        label="Date of Birth"
                        placeholder="MM-DD-YYYY"
                        :rules="[(val) => validateRequired(val)]"
                        @click="openDatePicker('qDateProxy1')"
                        :disable="true"
                      >
                        <!-- <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy1"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="localPickupByDOB1"
                                :options="isDateAllowed"
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template> -->
                        <template v-slot:prepend>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              cover
                              ref="qDateProxy1"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="localPickupByDOB1"
                                mask="MM-DD-YYYY"
                                :options="minTodayDate"
                              >
                                <div
                                  class="row items-center justify-end q-gutter-sm"
                                >
                                  <q-btn
                                    label="Cancel"
                                    color="primary"
                                    flat
                                    v-close-popup
                                    @click="cancelDateSelection1"
                                  />
                                  <q-btn
                                    label="OK"
                                    color="primary"
                                    flat
                                    v-close-popup
                                    @click="selectDate1"
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <div class="row q-col-gutter-x-lg">
                  <div class="col-xs-12">
                    <div class="form-field">
                      <q-checkbox
                        v-model="adddAlternatePickup"
                        :label="
                          fulfillmentObject.codeName === 'scd'
                            ? 'Add an alternate person to handover the order (optional)'
                            : 'Add an alternate pickup person (optional)'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-12 order-5 order-md-4">
              <div class="cs-review-info">
                <div class="row">
                  <div v-if="adddAlternatePickup" class="col-xs-12 col-md-12">
                    <p
                      :class="[
                        'text-body1 text-dark',
                        { 'text-justify': isMobile },
                      ]"
                      v-show="fulfillmentObject.codeName == 'sp'"
                    >
                      If an alternate person will be picking up the order on
                      your behalf, their details must be entered below. An
                      alternate person's <strong>identification</strong> will be
                      required when picking up the order from the store. If your
                      order includes any age-restricted items,
                      <strong>age verification</strong> will also be necessary.
                      In both cases, the alternate person must present a valid
                      <strong>driver's license</strong> to be scanned and the
                      <strong>confirmation code</strong> (provided in the
                      email).
                    </p>
                    <p
                      :class="[
                        'text-body1 text-dark',
                        { 'text-justify': isMobile },
                      ]"
                      v-show="fulfillmentObject.codeName == 'scd'"
                    >
                      If an alternate person will be receiving the order on your
                      behalf, their details must be entered below. An alternate
                      person's <strong>identification</strong> will be required
                      when receiving the order. If your order includes any
                      age-restricted items,
                      <strong>age verification</strong> will also be necessary.
                      In both cases, the alternate person must present a valid
                      <strong>driver's license</strong> to be scanned and the
                      <strong>confirmation code</strong> (provided in the
                      email).
                    </p>

                    <!-- <p>Alternate Pickup Person</p> -->
                    <p
                      v-show="fulfillmentObject.codeName == 'sp'"
                      class="text-dark text-weight-bold"
                      >Alternate Pickup Person Name as mentioned in DL</p
                    >
                    <p
                      v-show="fulfillmentObject.codeName == 'scd'"
                      class="text-dark text-weight-bold"
                      >Alternate Receiving Person Name as mentioned in DL</p
                    >
                  </div>
                  <div class="col-xs-12 col-md-8">
                    <div
                      v-if="adddAlternatePickup"
                      class="row q-col-gutter-x-lg"
                    >
                      <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="form-field">
                          <q-input
                            outlined
                            v-model.trim="pickupPerson.alternateUserFirstName"
                            label="First Name"
                            placeholder="First Name"
                            dense
                            :rules="[
                              (val) => validateRequired(val),
                              this.validatePersonName(
                                pickupPerson.alternateUserFirstName,
                                'Please enter a valid name'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                          />
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="form-field">
                          <q-input
                            outlined
                            v-model.trim="pickupPerson.alternateUserLastName"
                            label="Last Name"
                            placeholder="Last Name"
                            dense
                            :rules="[
                              (val) => validateRequired(val),
                              this.validatePersonName(
                                pickupPerson.alternateUserLastName,
                                'Please enter a valid name'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                          />
                        </div>
                      </div>
                      <div
                        class="col-xs-12 col-sm-6 col-md-4"
                        v-show="showDOBField"
                      ></div>
                      <div
                        :class="
                          showDOBField
                            ? 'col-xs-12 col-sm-6 col-md-4'
                            : 'col-xs-12 col-sm-8'
                        "
                      >
                        <!-- <q-input
                      outlined
                      v-model="pickUpByPhone2"
                      label="Get Texted too (optional)"
                      placeholder="Your phone number"
                      v-mask="phoneMask"
                      :rules="[this.validatePhone()]"
                      dense
                    /> -->
                        <div class="form-field">
                          <q-input
                            outlined
                            v-model="pickupPerson.pickUpByEmail2"
                            label="Email"
                            placeholder="Your Email"
                            dense
                            :rules="[
                              (val) => validateRequired(val),
                              (val) => validateEmail(val),
                            ]"
                            type="email"
                          />
                        </div>
                      </div>
                      <div
                        v-if="showDOBField"
                        class="col-xs-12 col-sm-6 col-md-4"
                      >
                        <div class="form-field">
                          <!-- <q-input
                            outlined
                            dense
                            v-model="pickupPerson.pickupByDOB2"
                            mask="date"
                            :rules="['date']"
                            label="Date of Birth"
                            placeholder="yyyy/mm/dd"
                          >
                            <template v-slot:append>
                              <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy
                                  ref="qDateProxy"
                                  transition-show="scale"
                                  transition-hide="scale"
                                >
                                  <q-date
                                    v-model="pickupPerson.pickupByDOB2"
                                    :options="isDateAllowed"
                                  >
                                    <div class="row items-center justify-end">
                                      <q-btn
                                        v-close-popup
                                        label="Close"
                                        color="primary"
                                        flat
                                      />
                                    </div>
                                  </q-date>
                                </q-popup-proxy>
                              </q-icon>
                            </template>
                          </q-input> -->
                          <q-input
                            outlined
                            dense
                            :value="selectedPickupByDOB2"
                            label="Date of Birth"
                            placeholder="MM-DD-YYYY"
                            :rules="[(val) => validateRequired(val)]"
                            @click="openDatePicker('qDateProxy2')"
                          >
                            <template v-slot:prepend>
                              <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy
                                  cover
                                  ref="qDateProxy2"
                                  transition-show="scale"
                                  transition-hide="scale"
                                >
                                  <q-date
                                    v-model="localPickupByDOB2"
                                    mask="MM-DD-YYYY"
                                    :options="minTodayDate"
                                  >
                                    <div
                                      class="row items-center justify-end q-gutter-sm"
                                    >
                                      <q-btn
                                        label="Cancel"
                                        color="primary"
                                        flat
                                        v-close-popup
                                        @click="cancelDateSelection"
                                      />
                                      <q-btn
                                        label="OK"
                                        color="primary"
                                        flat
                                        v-close-popup
                                        @click="selectDate"
                                      />
                                    </div>
                                  </q-date>
                                </q-popup-proxy>
                              </q-icon>
                            </template>
                            <template v-slot:append>
                              <q-avatar
                                size="1.4rem"
                                color="green"
                                text-color="white"
                                v-show="isDOBVerified === true"
                              >
                                <q-icon size="1rem" name="done" />
                              </q-avatar>
                              <q-avatar
                                text-color="red"
                                v-show="isDOBVerified === false"
                              >
                                <q-icon size="1.6rem" name="info" />
                                <q-tooltip
                                  content-class="bg-red"
                                  content-style="font-size: 13px"
                                  anchor="top end"
                                  self="bottom end"
                                >
                                  The alternate person seems underage as per
                                  DOB. <br />Please enter another alternate
                                  person or check DOB.
                                </q-tooltip>
                              </q-avatar>
                            </template>
                            <!-- <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-time
                            v-model="closureSchedule"
                            mask="YYYY-MM-DD hh:mm A"
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                label="Cancel"
                                color="primary"
                                flat
                                v-close-popup
                                @click="cancelDateSelection"
                              />
                              <q-btn
                                label="OK"
                                color="primary"
                                flat
                                v-close-popup
                                @click="selectDate"
                              />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template> -->
                          </q-input>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row q-col-gutter-x-lg"
                      v-if="
                        fulfillmentObject &&
                        fulfillmentObject.deliveryMethod == 1 &&
                        !fulfillmentObject.isSignatureRequired &&
                        !fulfillmentObject.isAgeRestricted
                      "
                    >
                      <div class="col-xs-12">
                        <q-checkbox
                          v-model="isContactlessdelivery"
                          label="Do you want contactless delivery?"
                        />
                      </div>
                    </div>

                    <div class="row q-col-gutter-x-lg">
                      <div class="col-12 col-sm-12 col-md-8">
                        <div class="stepsTimeSlot q-mt-md">
                          <h5 :class="isMobile ? 'text-primary' : ''">
                            Order Instructions
                            <!-- <q-btn round unelevated size="sm" dense icon="edit"></q-btn> -->
                          </h5>
                          <!-- <div class="text-caption">Please note that Delivery Instructions are not a guarantee</div> -->
                        </div>
                        <div class="form-field__area pb-0">
                          <q-input
                            outlined
                            v-model="deliveryInstruction"
                            type="textarea"
                            color="grey"
                            label="Enter Instruction"
                            maxlength="255"
                          >
                          </q-input
                          ><span class="float-right text-caption"
                            >{{ deliveryInstruction?.length || 0 }}/255</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="orderReviewInfo" v-else>
          <h2 class="stepsTitle" v-show="isMobile">PickUp Information</h2>
          <div class="row q-col-gutter-x-lg">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h5>
                {{
                  fulfillmentObject.codeName === 'scd' ||
                  fulfillmentObject.codeName === 'sd'
                    ? 'Deliver To'
                    : 'Primary Pickup Person'
                }}
              </h5>
              <h5 v-show="fulfillmentObject.codeName === 'scd'">
                Primary Person
              </h5>
              <p class="q-pb-md">
                Name:
                {{
                  pickupPerson.primaryUserFirstName +
                  ' ' +
                  pickupPerson.primaryUserLastName
                }}
                <br />
                Email:
                {{ pickupPerson.pickUpByEmail1 || 'NA' }}
                <!-- {{ maskPhoneNumber(pickUpByPhone1) }} -->
              </p>

              <!-- <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p> -->

              <div
                v-if="
                  adddAlternatePickup &&
                  pickupPerson.alternateUserFirstName != ''
                "
              >
                <h5>Alternate Person</h5>
                <p class="q-pb-md">
                  Name:
                  {{
                    pickupPerson.alternateUserFirstName +
                    ' ' +
                    pickupPerson.alternateUserLastName
                  }}<br />
                  <!-- Phone Number:
              {{ maskPhoneNumber(pickUpByPhone2) || 'NA' }} -->
                  Email: {{ pickupPerson.pickUpByEmail2 || 'NA' }}
                  <template v-if="pickupPerson.pickupByDOB2">
                    <br />
                    Date Of Birth:
                    {{
                      $options.filters.formatDate(pickupPerson.pickupByDOB2) ||
                      'NA'
                    }}
                  </template>
                </p>
                <!-- <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p> -->
              </div>
              <div
                v-if="
                  fulfillmentObject &&
                  fulfillmentObject.deliveryMethod == 1 &&
                  !fulfillmentObject.isSignatureRequired &&
                  !fulfillmentObject.isAgeRestricted
                "
              >
                <div class="col">
                  <q-checkbox
                    dense
                    v-model="isContactlessdelivery"
                    label="Do you want contactless delivery?"
                  />
                </div>
              </div>
            </div>
            <!-- <div
          v-if="adddAlternatePickup && pickUpByName2 != ''"
          class="col-xs-12 col-sm-6 col-md-4"
        >
          <h5>Alternate Person</h5>
          <p class="q-pb-md">
            Name: {{ pickUpByName2 }}<br />Phone Number:
            {{ maskPhoneNumber(pickUpByPhone2) || 'NA' }}
            {{ pickUpByEmail2 || 'NA' }}
          </p>
          <p>
            <a href="javascript:void(0)" class="link-dark">
              + Get a email when your order is ready.
            </a>
          </p>
        </div> -->
            <div
              class="col-xs-12 col-sm-6 col-md-4"
              v-if="fulfillmentObject.codeName == 'sp'"
            >
              <h5>Pickup Method</h5>
              <q-list class="shipping-method-list">
                <q-item dense tag="label" class="q-pa-sm r-8">
                  <q-item-section side>
                    <q-radio
                      dense
                      v-model="pickUpMethod"
                      :val="1"
                      color="primary"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="text-weight-bold">
                      In Store Pickup
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item dense tag="label" class="q-pa-sm r-8">
                  <q-item-section side>
                    <q-radio
                      dense
                      v-model="pickUpMethod"
                      :val="2"
                      color="primary"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="text-weight-bold">
                      Curbside Pickup
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div
              v-if="isSlotBookingRequired && fulfillmentObject.codeName == 'sp'"
              class="col-xs-12 col-sm-6 col-md-4"
            >
              <div class="stepsTimeSlot">
                <h5>
                  Time Slot
                  <a
                    href="javascript:void(0);"
                    class="text-tertiary"
                    @click="
                      openReserSlot(fulfillmentObject.codeName.toUpperCase())
                    "
                  >
                    Edit
                  </a>
                </h5>
                <p
                  v-if="formatedCustomerSPBookedSlot"
                  v-html="formatedCustomerSPBookedSlot"
                />
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-6"
              :class="
                this.selectedFulfillmentCodeName == 'sp'
                  ? 'col-md-12'
                  : this.selectedFulfillmentCodeName == 'scd'
                  ? 'col-md-8'
                  : 'col-md-6'
              "
            >
              <div class="stepsTimeSlot">
                <h5 :class="isMobile ? 'text-primary' : ''">
                  Order Instructions
                </h5>
                <!-- <p>instruction value</p> -->
                <p class="text-caption word-break-word">
                  {{
                    this.cartPersistedInfo?.deliveryInstruction || '(not set)'
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepperNavigationBtn
        v-show="!isViewMode"
        :label="stepperNavigationBtnLabel"
        :loading="loading"
        :isReviewOrder="isReviewOrder"
        :cancelAction="cancelEdit"
        :disable="isDisablePickupInfo"
      />
    </q-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StepperNavigationBtn from './StepperNavigationBtn'

export default {
  name: 'PickUpinfo',
  components: { StepperNavigationBtn },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    cartNumber: {
      type: String,
      default: '',
    },
    fulfillmentObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  created() {
    this.pickupPerson = Object.assign({}, this.currentPickupInfo)
    this.deliveryInstruction = this.cartPersistedInfo?.deliveryInstruction
    if (this.isLoggedIn) {
      this.pickupPerson.primaryUserFirstName = this.currentUser.firstName || ''
      this.pickupPerson.primaryUserLastName = this.currentUser.lastName || ''
      this.pickupPerson.pickUpByEmail1 = this.currentUser.email || ''
      this.pickupPerson.pickupByDOB1 = this.currentUser.dob
        ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
        : ''
    } else if (this.guestUser) {
      this.pickupPerson.primaryUserFirstName = this.guestUser.firstName || ''
      this.pickupPerson.primaryUserLastName = this.guestUser.lastName || ''
      this.pickupPerson.pickUpByEmail1 = this.guestUser.email || ''
      this.pickupPerson.pickupByDOB1 = this.guestUser.dob
        ? this.$options.filters.formatDate(this.guestUser.dob, 'YYYY-MM-DD')
        : ''
    }

    if (
      this.showDOBField &&
      this.adddAlternatePickup &&
      this.pickupPerson.pickupByDOB2
    ) {
      this.localPickupByDOB2 = this.pickupPerson.pickupByDOB2
      this.validateDOB(this.pickupPerson.pickupByDOB2)
    }
  },
  data() {
    return {
      loading: false,
      deliveryInstruction: this.cartPersistedInfo?.deliveryInstruction,
      isViewMode: this.isReviewOrder,
      fulfillmentMethodID: this.fulfillmentObject.fulfillmentMethodID,
      pickupPerson: {
        primaryUserFirstName: '',
        primaryUserLastName: '',
        pickUpByPhone1: '',
        pickUpByEmail1: '',
        pickupByDOB1: '',
        alternateUserFirstName: '',
        alternateUserLastName: '',
        pickUpByPhone2: '',
        pickUpByEmail2: '',
        pickupByDOB2: '',
      },
      alternatePickup: null,
      localPickupByDOB1: null,
      localPickupByDOB2: null,
      isDOBVerified: null,
    }
  },
  computed: {
    ...mapGetters('common', ['isSlotBookingRequired']),
    ...mapGetters('cart', ['pickupInfoByFulfillmentId', 'currentCartDetails']),
    ...mapGetters('reserveSlot', ['formatBookedSlot']),
    ...mapGetters('persistedLocal', ['getCartPersistedInfo']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('guest', ['guestUser']),
    cartPersistedInfo() {
      return this.getCartPersistedInfo(this.cartNumber)
    },
    isContactlessdelivery: {
      get() {
        return this.$store.getters['persistedLocal/isContactlessdelivery']
      },
      set(val) {
        this.$store.commit('persistedLocal/SET_CONTACTLESS_DELIVERY', val)
      },
    },
    pickUpMethod: {
      get() {
        return this.cartPersistedInfo?.pickupType || 1
      },
      set(val) {
        if (!this.deliveryInstruction) {
          this.deliveryInstruction = this.cartPersistedInfo?.deliveryInstruction
        }

        this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
          cartNumber: this.cartNumber,
          data: {
            pickupType: val,
            deliveryInstruction: this.deliveryInstruction,
          },
        })
      },
    },
    currentPickupInfo() {
      return this.pickupInfoByFulfillmentId(this.fulfillmentMethodID)
    },
    pickUpByName1: {
      get() {
        return this.pickupPerson.pickUpByName1 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByName1', val)
      },
    },
    pickUpByName2: {
      get() {
        return this.pickupPerson.pickUpByName2 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByName2', val)
      },
    },
    // pickUpByPhone1: {
    //   get() {
    //     return this.pickupPerson.pickUpByPhone1 || ''
    //   },
    //   set(val) {
    //     this.$set(this.pickupPerson, 'pickUpByPhone1', val)
    //   },
    // },
    // pickUpByPhone2: {
    //   get() {
    //     return this.pickupPerson.pickUpByPhone2 || ''
    //   },
    //   set(val) {
    //     this.$set(this.pickupPerson, 'pickUpByPhone2', val)
    //   },
    // },
    pickUpByEmail1: {
      get() {
        return this.pickupPerson.pickUpByEmail1 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByEmail1', val)
      },
    },
    pickUpByEmail2: {
      get() {
        return this.pickupPerson.pickUpByEmail2 || ''
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickUpByEmail2', val)
      },
    },
    pickupByDOB1: {
      get() {
        return this.pickupPerson.pickupByDOB1
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickupByDOB1', val)
      },
    },
    pickupByDOB2: {
      get() {
        return this.pickupPerson.pickupByDOB2
      },
      set(val) {
        this.$set(this.pickupPerson, 'pickupByDOB2', val)
      },
    },
    adddAlternatePickup: {
      get() {
        return this.alternatePickup !== null
          ? this.alternatePickup
          : this.pickupPerson.alternateUserFirstName
          ? true
          : false
      },
      set(value) {
        this.alternatePickup = value
      },
    },
    objRequest() {
      return {
        orderNumer: this.cartNumber,
        fulfillmentMethodID: this.fulfillmentMethodID,
        locationID: this.currentLocationId,
        primaryUserFirstName: this.pickupPerson.primaryUserFirstName,
        primaryUserLastName: this.pickupPerson.primaryUserLastName,
        // pickUpByPhone1: this.unmask(this.pickUpByPhone1, this.phoneMask),
        pickUpByEmail1: this.pickupPerson.pickUpByEmail1,
        pickupByDOB1: this.pickupPerson.pickupByDOB1,
        alternateUserFirstName: this.adddAlternatePickup
          ? this.pickupPerson.alternateUserFirstName
          : '',
        alternateUserLastName: this.adddAlternatePickup
          ? this.pickupPerson.alternateUserLastName
          : '',
        // pickUpByPhone2: this.adddAlternatePickup
        //   ? this.unmask(this.pickUpByPhone2, this.phoneMask)
        //   : '',
        pickUpByEmail2: this.adddAlternatePickup
          ? this.pickupPerson.pickUpByEmail2
          : '',
        pickupByDOB2:
          this.showDOBField && this.adddAlternatePickup
            ? this.pickupPerson.pickupByDOB2
            : '',
        deliveryInstruction: this.deliveryInstruction,
        customerID: this.currentCartDetails.customerID,
      }
    },
    formatedCustomerSPBookedSlot() {
      return this.formatBookedSlot('sp')
    },
    // formatedCustomerISBookedSlot() {
    //   return this.formatBookedSlot('is')
    // },
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : ''
    },
    cartHasAgeRestrictedItem() {
      return (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.currentCartDetails?.ageRequired > 0
      )
    },
    isDisablePickupInfo() {
      if (this.adddAlternatePickup && this.showDOBField) {
        return !!!this.isDOBVerified
      } else return false
    },
    selectedPickupByDOB1() {
      let selectedDate = ''
      if (this.pickupPerson?.pickupByDOB1) {
        selectedDate = this.$options.filters.formatDate(
          this.pickupPerson.pickupByDOB1,
          'MM-DD-YYYY'
        )
      }
      return selectedDate
    },
    selectedPickupByDOB2() {
      let selectedDate = ''
      if (this.pickupPerson?.pickupByDOB2)
        selectedDate = this.pickupPerson.pickupByDOB2
      return selectedDate
    },
    showDOBField() {
      return (
        this.cartHasAgeRestrictedItem &&
        this.businessConfigurations?.enableAgeRestrictedFulfillment
      )
    },
  },
  methods: {
    minTodayDate(d) {
      // return d >= this.formatDate(Date.now(), 'MM/DD/YYYY')
      return new Date().toISOString().slice(0, 10).split('-').join('/') >= d
    },
    selectDate() {
      this.pickupPerson.pickupByDOB2 = this.localPickupByDOB2
      this.validateDOB(this.localPickupByDOB2)
    },
    cancelDateSelection() {
      this.localPickupByDOB2 = this.pickupPerson.pickupByDOB2
    },
    selectDate1() {
      this.pickupPerson.pickupByDOB1 = this.localPickupByDOB1
    },
    cancelDateSelection1() {
      this.localPickupByDOB1 = this.pickupPerson.pickupByDOB1
    },
    isValidDate(val) {
      return /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.test(val)
    },
    onChangePickupPersonDOB(val) {
      let isValid = this.isValidDate(val)
      if (isValid) this.pickupPerson.pickupByDOB2 = val
    },
    validateDOB(date) {
      let years = this.calculateAgeByDate(new Date(date))
      if (years >= this.currentCartDetails.ageRequired) {
        this.isDOBVerified = true
      } else {
        this.isDOBVerified = false
      }
    },
    calculateAgeByDate(dateOfBirth, dateToCalculate = new Date()) {
      const dob = new Date(dateOfBirth).getTime(),
        dateToCompare = new Date(dateToCalculate).getTime(),
        age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000)
      return Math.floor(age)
    },
    openReserSlot(bookingType) {
      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: true,
        properties: {
          activeTab: bookingType,
        },
      })
    },
    maskPhoneNumber(phoneNumber) {
      if (phoneNumber && phoneNumber.length == 10) {
        return (
          '(' +
          phoneNumber.slice(0, 3) +
          ')-' +
          phoneNumber.slice(3, 6) +
          '-' +
          phoneNumber.slice(6, 10)
        )
      }
      return 'NA'
    },
    isDateAllowed(date) {
      return new Date().toISOString().slice(0, 10).split('-').join('/') > date
    },
    submitFormAndContinue() {
      this.$refs.pickupInforForm.validate().then(async (isValid) => {
        if (isValid) {
          this.loading = true
          this.$store.commit('persistedLocal/SET_CART_PERSISTED_INFO', {
            cartNumber: this.cartNumber,
            data: {
              rateID: this.pickUpMethod,
              deliveryInstruction: this.deliveryInstruction,
            },
          })

          this.$store
            .dispatch('cart/updatePickupInfo', this.objRequest)
            .then((response) => {
              this.loading = false
              this.pickupPerson = Object.assign({}, this.currentPickupInfo)
              if (this.isReviewOrder) {
                this.isViewMode = true
              } else {
                this.next()
              }
            })
        }
        return
      })
    },
    cancelEdit() {
      this.pickupPerson = Object.assign({}, this.currentPickupInfo)
      this.isViewMode = true
      this.deliveryInstruction =
        this.cartPersistedInfo?.deliveryInstruction || ''
      this.adddAlternatePickup = !!this.pickupPerson?.alternateUserFirstName
    },
    editInfo() {
      this.pickupPerson = Object.assign({}, this.currentPickupInfo)

      if (this.pickupPerson?.alternateUserFirstName) {
        this.adddAlternatePickup = true
      } else {
        this.adddAlternatePickup = false
      }

      this.isViewMode = false
      this.deliveryInstruction =
        this.cartPersistedInfo?.deliveryInstruction || ''
      this.isViewMode = false
    },
    openDatePicker(refKey) {
      if (this.$refs[refKey]) this.$refs[refKey].show()
    },
  },
  watch: {
    currentPickupInfo: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.pickupPerson = Object.assign({}, this.currentPickupInfo)
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.cs-review-info {
  .q-field {
    .q-field__bottom {
      max-width: 125px;
    }
  }
}
</style>
