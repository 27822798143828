<template>
  <div>
    <q-form ref="personalInfoForm" @submit="onSubmit" class="position-inherit">
      <div :class="isMobile ? 'checkoutHeightxs' : ''">
        <template v-if="!isViewMode">
          <div class="row">
            <div class="col-xs-12 col-md-12" v-show="isMobile">
              <h2 class="stepsTitle" v-html="title" />
            </div>

            <div class="col-xs-12">
              <div class="orderPickupInfo" v-if="!isLoggedIn && !guestUser">
                <div class="row justify-between items-start">
                  <div class="col-12 col-md-5 order-last order-md-first">
                    <div class="cs-review-info">
                      <p
                        class="text-tertiary"
                        :class="isMobile ? 'text-center' : ''"
                      >
                        <strong>Let's continue as Guest!</strong>
                      </p>
                      <div class="col-12 order-3 order-md-1" v-if="!isLoggedIn">
                        <p
                          class="text-body1 text-dark"
                          v-show="fulfillmentObject.codeName == 'sp'"
                        >
                          Your <strong>identification</strong> will be required
                          when you come to pick up your order. If your order
                          includes any age-restricted items,
                          <strong>age verification</strong> will also be
                          necessary. In both cases, you must present a valid
                          <strong>driver's license</strong> to be scanned and
                          the <strong>confirmation code</strong> (provided in
                          the email). So, enter the details that are mentioned
                          in your <strong>driver's license</strong>.
                        </p>
                        <p
                          class="text-body1 text-dark"
                          v-show="fulfillmentObject.codeName == 'scd'"
                        >
                          Your <strong>identification</strong> will be required
                          when you receive your order. If your order includes
                          any age-restricted items,
                          <strong>age verification</strong> will also be
                          necessary. In both cases, you must present a valid
                          <strong>driver's license</strong> to be scanned and
                          the <strong>confirmation code</strong> (provided in
                          the email). So, enter the details that are mentioned
                          in your <strong>driver's license</strong>.
                        </p>
                      </div>
                      <div class="row q-col-gutter-x-lg">
                        <div class="col-xs-6 col-md-6">
                          <div class="form-field">
                            <q-input
                              outlined
                              dense
                              v-model="personalInfo.firstName"
                              label="First Name*"
                              :rules="[
                                (val) => validateRequired(val),
                                this.validatePersonName(
                                  personalInfo.firstName,
                                  'Please enter a valid name'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                              :filled="personalInfoSubmitted"
                              :readonly="personalInfoSubmitted"
                            />
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-6">
                          <div class="form-field">
                            <q-input
                              outlined
                              dense
                              v-model="personalInfo.lastName"
                              label="Last Name*"
                              :rules="[
                                (val) => validateRequired(val),
                                this.validatePersonName(
                                  personalInfo.lastName,
                                  'Please enter a valid name'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                              :filled="personalInfoSubmitted"
                              :readonly="personalInfoSubmitted"
                            />
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-12">
                          <div class="form-field">
                            <q-input
                              outlined
                              dense
                              v-model="personalInfo.email"
                              label="Email*"
                              :rules="[
                                (val) => validateRequired(val),
                                (val) => validateEmail(val),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                              type="email"
                              :filled="
                                personalInfoSubmitted && isEmailFieldEdit
                              "
                              :readonly="
                                personalInfoSubmitted && isEmailFieldEdit
                              "
                              :disable="loadingForEmailField"
                              ref="emailInput"
                            >
                              <template
                                v-slot:append
                                v-if="personalInfoSubmitted && !isGuestVerified"
                              >
                                <q-btn
                                  unelevated
                                  no-caps
                                  dense
                                  text-color="grey-7"
                                  size="10px"
                                  icon="edit"
                                  class="no-hover"
                                  @click="saveEmailField"
                                  v-if="isEmailFieldEdit"
                                />
                                <q-btn
                                  unelevated
                                  no-caps
                                  dense
                                  text-color="primary"
                                  size="13px"
                                  label="Update"
                                  class="no-hover text-weight-bold text-uppercase"
                                  @click="saveEmailField"
                                  :loading="loadingForEmailField"
                                  v-else
                                />
                              </template>
                            </q-input>
                          </div>
                        </div>
                        <div
                          class="col-xs-12 col-md-12"
                          v-if="
                            !isLoggedIn &&
                            cartHasAgeRestrictedItem &&
                            businessConfigurations?.enableAgeRestrictedFulfillment
                          "
                        >
                          <div class="form-field">
                            <q-input
                              outlined
                              dense
                              :value="selectedDOB"
                              label="Date of Birth*"
                              placeholder="MM-DD-YYYY"
                              :rules="[(val) => validateRequired(val)]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="40"
                              aria-label="ftxcommerce"
                              :filled="personalInfoSubmitted"
                              :readonly="personalInfoSubmitted"
                              @click="openDatePicker"
                            >
                              <template v-slot:prepend>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy
                                    cover
                                    ref="qDateProxy"
                                    transition-show="scale"
                                    transition-hide="scale"
                                    v-if="!personalInfoSubmitted"
                                  >
                                    <q-date
                                      v-model="localDOB"
                                      mask="MM-DD-YYYY"
                                      :options="minTodayDate"
                                    >
                                      <div
                                        class="row items-center justify-end q-gutter-sm"
                                      >
                                        <q-btn
                                          label="Cancel"
                                          color="primary"
                                          flat
                                          v-close-popup
                                          @click="cancelDateSelection"
                                        />
                                        <q-btn
                                          label="OK"
                                          color="primary"
                                          flat
                                          v-close-popup
                                          @click="selectDate"
                                        />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                              <template v-slot:append>
                                <q-avatar
                                  size="1.4rem"
                                  color="green"
                                  text-color="white"
                                  v-show="isDOBVerified === true"
                                >
                                  <q-icon size="1rem" name="done" />
                                </q-avatar>
                                <q-avatar
                                  text-color="red"
                                  v-show="isDOBVerified === false"
                                >
                                  <q-icon size="1.6rem" name="info" />
                                  <q-tooltip
                                    content-class="bg-red"
                                    content-style="font-size: 13px"
                                    anchor="top end"
                                    self="bottom end"
                                  >
                                    The person seems underage as per DOB.
                                  </q-tooltip>
                                </q-avatar>
                              </template>
                            </q-input>
                          </div>
                        </div>
                        <div class="col-12 col-md-12">
                          <div class="form-field pb-0">
                            <div class="text-left q-mb-none text-body2">
                              <div class="row q-col-gutter-sm">
                                <div class="col-auto">
                                  <q-checkbox
                                    dense
                                    v-model="isPolicyAccepted"
                                    @input="toggleLegal"
                                    class="q-mr-xs"
                                  />
                                </div>
                                <div class="col">
                                  <span class="inline-block vertical-middle">
                                    I agree to the
                                    <span
                                      class="link-primary text-underline cursor-pointer"
                                      @click="
                                        changeDialogState({
                                          dialog: 'LegalDialog',
                                          val: true,
                                        })
                                      "
                                      >Terms of Service</span
                                    >
                                    and
                                    <span
                                      @click="
                                        changeDialogState({
                                          dialog: 'LegalDialog',
                                          val: true,
                                          properties: {
                                            isPrivacy: true,
                                          },
                                        })
                                      "
                                      class="link-primary text-underline cursor-pointer"
                                    >
                                      Privacy Policy </span
                                    >.</span
                                  >
                                </div>
                              </div>
                            </div>
                            <p
                              v-if="isLegalAgreementUncheck"
                              class="text-negative text-caption text-left q-mb-none"
                              >You must agree to our Terms of Service and
                              Privacy Policy.</p
                            >
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-12">
                          <div
                            class="row q-pt-md"
                            v-if="
                              personalInfoSubmitted &&
                              isEmailFieldEdit &&
                              !isGuestVerified
                            "
                          >
                            <div class="col-12">
                              <p>
                                A 6-digit one-time password (OTP) is sent to
                                <span class="text-secondary text-weight-bold">
                                  {{ email }}
                                </span>
                              </p>
                            </div>
                            <div class="col-12">
                              <div class="row q-col-gutter-md items-center">
                                <div class="col-12 col-md-9">
                                  <OTPInput
                                    ref="otpInput"
                                    input-classes="otp-input"
                                    separator=""
                                    :num-inputs="otpLength"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    @on-change="handleOnChange"
                                  />
                                </div>
                                <div class="col-12 col-md-3">
                                  <q-btn
                                    unelevated
                                    no-caps
                                    rounded
                                    outline
                                    color="secondary"
                                    label="Verify"
                                    padding="10px 8px"
                                    size="16px"
                                    class="q-btn-lg full-width"
                                    @click="verifyOTP"
                                    :loading="verifying"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-12">
                              <p class="q-mt-md" v-show="resendSeconds > 0">
                                Not received your code? Resend it in
                                <span class="text-secondary">
                                  {{ formatOTPSeconds(resendSeconds) }}s
                                </span>
                              </p>
                              <p class="q-mt-md" v-show="resending">
                                Resending Otp..
                              </p>
                              <p
                                class="q-mt-md"
                                v-show="resendSeconds <= 0 && !resending"
                              >
                                Didn't get OTP ?
                                <q-btn
                                  type="button"
                                  :disable="resendSeconds > 0"
                                  unelevated
                                  no-caps
                                  label="Resend It"
                                  color="transparent"
                                  text-color="primary"
                                  class="no-hover text-underline"
                                  size="12px"
                                  padding="0"
                                  @click="resendCode"
                                />
                              </p>
                            </div>
                            <div
                              class="col-12"
                              v-show="alertData.countDown > 0"
                            >
                              <Alert
                                variant="danger"
                                :show="alertData.countDown"
                                @dismissed="alertData.countDown = 0"
                              >
                                <span class="text-body2 text-weight-bold">
                                  {{ alertData.message }}
                                </span>
                              </Alert>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-auto" style="order: 2">
                    <div
                      class="orText"
                      :class="
                        $q.screen.lt.md ? 'q-my-sm' : 'vertical h200 q-mx-lg'
                      "
                    >
                      <span>OR</span>
                    </div>
                  </div>

                  <div
                    class="col-12 col-md-5 order-first order-md-last text-center"
                  >
                    <div class="text-body1 text-tertiary q-mb-sm">
                      <strong>Sign In</strong> or <strong>Sign Up</strong> for
                      exciting benefits
                    </div>
                    <div class="row q-col-gutter-md">
                      <div class="col-6">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            rounded
                            color="secondary"
                            label="Sign In"
                            class="full-width q-btn-lg"
                            @click="loginWithIdentity(false)"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            rounded
                            outline
                            color="secondary"
                            label="Sign Up For New Account"
                            class="full-width q-btn-lg"
                            @click="loginWithIdentity(true, ' aat:signup')"
                          />
                        </div>
                      </div>
                      <div class="col-12 relative-position">
                        <div class="form-action pb-0">
                          <q-btn
                            no-caps
                            rounded
                            unelevated
                            color="tertiary"
                            label="Sign Up Using Existing Account *"
                            class="full-width q-btn-lg"
                            @click="
                              loginWithIdentity(
                                true,
                                ' aat:signup sat:CompleteRegistration'
                              )
                            "
                          />
                        </div>
                        <div
                          class="absolute-top-right q-mt-sm q-pt-xs q-mr-sm"
                          v-if="isMobile"
                        >
                          <q-btn
                            no-caps
                            round
                            unelevated
                            outlined
                            color="white"
                            text-color="tertiary"
                            size="sm"
                            class="q-mt-md q-mr-xs"
                            icon="info"
                            @click="
                              showSignUpDescription = !showSignUpDescription
                            "
                          />
                        </div>
                        <transition
                          appear
                          enter-active-class="animated fadeIn"
                          leave-active-class="animated fadeOut"
                        >
                          <div
                            class="text-caption line-height-normal q-mt-sm q-mb-none"
                            v-show="showSignUpDescription || !isMobile"
                          >
                            * Please use this option if you have already
                            registered inside the store and never created a
                            login. It will associate all benefits you may have
                            earned in the past with your newly created login
                            here. you already registered in the store, you can
                            sign up using that account for the E-Commerce site.
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>

                <!--  -->
              </div>
              <div class="orderReviewInfo" v-else>
                <div class="row justify-between items-start" v-if="guestUser">
                  <div class="col-12 col-md-5 order-last order-md-first">
                    <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-4">
                        <h5 class="h5" :class="isMobile ? 'text-primary' : ''"
                          >Full Name</h5
                        >
                        <p class="p q-pb-md">{{
                          guestUserName || '(not set)'
                        }}</p>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6">
                        <div
                          class="stepsTimeSlot"
                          v-show="guestUser.phoneNumber && !guestUser.email"
                        >
                          <h5
                            class="h5"
                            :class="isMobile ? 'text-primary' : ''"
                          >
                            Phone
                          </h5>
                          <p class="p text-caption word-break-word">
                            {{
                              formatPhoneNumber(guestUser.phoneNumber) ||
                              '(not set)'
                            }}
                          </p>
                        </div>
                        <div class="stepsTimeSlot" v-show="guestUser.email">
                          <h5
                            class="h5"
                            :class="isMobile ? 'text-primary' : ''"
                          >
                            Email Address
                          </h5>
                          <p class="p text-caption word-break-word">
                            {{ guestUser.email || '(not set)' }}
                          </p>
                        </div>
                      </div>

                      <div
                        class="orderPickupInfo"
                        :class="[isMobile ? 'q-mt-lg' : 'q-mt-sm']"
                      >
                        <p class="p text-primaryOnBody">
                          We will communicate with you regarding this order via
                          provided email address.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-auto" style="order: 2">
                    <div
                      class="orText"
                      :class="
                        $q.screen.lt.md ? 'q-my-sm' : 'vertical h200 q-mx-lg'
                      "
                    >
                      <span>OR</span>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-5 order-first order-md-last text-center"
                  >
                    <div class="text-body1 text-tertiary q-mb-sm">
                      <strong>Sign In</strong> or <strong>Sign Up</strong> for
                      exciting benefits
                    </div>
                    <div class="row q-col-gutter-md">
                      <div class="col-6">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            rounded
                            color="secondary"
                            label="Sign In"
                            class="full-width q-btn-lg"
                            @click="loginWithIdentity(false)"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            rounded
                            outline
                            color="secondary"
                            label="Sign Up"
                            class="full-width q-btn-lg"
                            @click="loginWithIdentity(true, ' aat:signup')"
                          />
                        </div>
                      </div>
                      <div class="col-12 relative-position">
                        <div class="form-action pb-0">
                          <q-btn
                            no-caps
                            rounded
                            unelevated
                            color="tertiary"
                            label="Sign Up Using Existing Account *"
                            class="full-width q-btn-lg"
                            @click="
                              loginWithIdentity(
                                true,
                                ' aat:signup sat:CompleteRegistration'
                              )
                            "
                          />
                        </div>
                        <div
                          class="absolute-top-right q-mt-sm q-pt-xs q-mr-sm"
                          v-if="isMobile"
                        >
                          <q-btn
                            no-caps
                            round
                            unelevated
                            outlined
                            color="white"
                            text-color="tertiary"
                            size="sm"
                            class="q-mt-md q-mr-xs"
                            icon="info"
                            @click="
                              showSignUpDescription = !showSignUpDescription
                            "
                          />
                        </div>
                        <transition
                          appear
                          enter-active-class="animated fadeIn"
                          leave-active-class="animated fadeOut"
                        >
                          <div
                            class="text-caption line-height-normal q-mt-sm q-mb-none"
                            v-show="showSignUpDescription || !isMobile"
                          >
                            * Please use this option if you have already
                            registered inside the store and never created a
                            login. It will associate all benefits you may have
                            earned in the past with your newly created login
                            here. you already registered in the store, you can
                            sign up using that account for the E-Commerce site.
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row q-col-gutter-x-lg" v-else>
                  <div class="col-xs-12 col-sm-6 col-md-4">
                    <h5 class="h5" :class="isMobile ? 'text-primary' : ''"
                      >Full Name</h5
                    >
                    <p class="p q-pb-md">{{
                      currentUserName || '(not set)'
                    }}</p>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="stepsTimeSlot">
                      <h5 class="h5" :class="isMobile ? 'text-primary' : ''">
                        Email Address
                      </h5>
                      <p class="p text-caption word-break-word">
                        {{ currentUser.email || '(not set)' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="orderPickupInfo"
                    :class="[isMobile ? 'q-mt-lg' : 'q-mt-sm']"
                  >
                    <p class="p text-primaryOnBody">
                      We will communicate with you regarding this order via
                      provided email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LegalDialog />
        </template>
        <div class="orderReviewInfo" v-else>
          <div class="row q-col-gutter-x-lg" v-if="guestUser">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h5 class="h5" :class="isMobile ? 'text-primary' : ''"
                >Full Name</h5
              >
              <p class="p q-pb-md">{{ guestUserName || '(not set)' }}</p>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <div class="stepsTimeSlot">
                <h5 class="h5" :class="isMobile ? 'text-primary' : ''">
                  Email Address
                </h5>
                <p class="p text-caption word-break-word">
                  {{ guestUser.email || '(not set)' }}
                </p>
              </div>
            </div>
            <div
              class="orderPickupInfo"
              :class="[isMobile ? 'q-mt-lg' : 'q-mt-sm']"
            >
              <p class="p text-primaryOnBody">
                We will communicate with you regarding this order via provided
                email address.
              </p>
            </div>
          </div>
          <div class="row q-col-gutter-x-lg" v-else>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <h5 class="h5" :class="isMobile ? 'text-primary' : ''"
                >Full Name</h5
              >
              <p class="p q-pb-md">{{ currentUserName || '(not set)' }}</p>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <div class="stepsTimeSlot">
                <h5 class="h5" :class="isMobile ? 'text-primary' : ''">
                  Email Address
                </h5>
                <p class="p text-caption word-break-word">
                  {{ currentUser.email || '(not set)' }}
                </p>
              </div>
            </div>
            <div
              class="orderPickupInfo"
              :class="[isMobile ? 'q-mt-lg' : 'q-mt-sm']"
            >
              <p class="p text-primaryOnBody">
                We will communicate with you regarding this order via provided
                email address.
              </p>
            </div>
          </div>
        </div>
      </div>
      <StepperNavigationBtn
        v-show="!isViewMode"
        :label="stepperNavigationBtnLabel"
        :loading="loading"
        :isReviewOrder="showCancelBtn"
        :cancelAction="cancelEdit"
        :disable="isStepperBtnDisable"
      />
      <!-- :disable="isStepperBtnDisable" -->
    </q-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StepperNavigationBtn from './StepperNavigationBtn'
import { formatPhoneNumber } from 'src/utils'

export default {
  name: 'PersonalInfo',
  components: {
    StepperNavigationBtn,
    OTPInput: () => import('src/components/otp-input'),
    LegalDialog: () => import('src/components/dialog/LegalDialog'),
  },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    cartNumber: {
      type: String,
      default: '',
    },
    fulfillmentObject: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  // created() {
  //   if (!this.isLoggedIn && !this.guestUser) this.isStepperBtnDisable = true
  // },
  data() {
    return {
      showSignUpDescription: false,
      isViewMode: this.isReviewOrder,
      // isGuestSelected: true,
      isGuestVerified: false,
      otp: '',
      otpLength: 6,
      isLegalAgreementUncheck: false,
      isPolicyAccepted: false,
      personalInfoSubmitted: false,
      personalInfo: {
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
      },
      tokenKey: null,
      email: '',
      isEmailFieldEdit: false,
      interval: null,
      resendSeconds: 0,
      defaultResendTime: 120,
      resending: false,
      alertData: {
        countDown: 0,
        message: '',
      },
      verifying: false,
      loading: false,
      loadingForEmailField: false,
      // isStepperBtnDisable: false,
      localDOB: null,
      isDOBVerified: null,
    }
  },
  // watch: {
  //   isGuestSelected(newVal, oldVal) {
  //     if (newVal != oldVal && !this.guestUser)
  //       this.isStepperBtnDisable = !newVal
  //   },
  // },
  computed: {
    ...mapGetters('common', ['checkSlotBookingRequired']),
    ...mapGetters('reserveSlot', [
      'customerSPSlotBookingID',
      'customerSCDSlotBookingID',
    ]),
    ...mapGetters('reserveSlot', ['formatBookedSlot']),
    ...mapGetters('guest', ['guestUser']),
    ...mapGetters('cart', ['pickupInfoByFulfillmentId', 'currentCartDetails']),
    ...mapGetters('payment', ['paymentMethods']),
    ...mapGetters('address', ['addresses', 'billingAddresses']),
    ...mapGetters('ageVerification', ['isOrderVerified']),
    stepperNavigationBtnLabel() {
      if (!this.isLoggedIn) {
        return 'Continue as Guest'
      }
      return this.isReviewOrder ? 'Apply' : ''
    },
    guestUserName() {
      return this.guestUser.firstName + ' ' + this.guestUser.lastName
    },
    currentPickupInfo() {
      return this.pickupInfoByFulfillmentId(
        this.fulfillmentObject.fulfillmentMethodID
      )
    },
    showCancelBtn() {
      let showBtn

      if (!this.isLoggedIn) {
        if (this.guestUser != null) {
          showBtn = this.isReviewOrder ? true : false
        }
      } else showBtn = this.isReviewOrder
      return showBtn
    },
    selectedDOB() {
      let selectedDate = ''
      if (this.personalInfo?.dob) selectedDate = this.personalInfo.dob
      return selectedDate
    },
    cartHasAgeRestrictedItem() {
      return (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.currentCartDetails?.ageRequired > 0
      )
    },
    isStepperBtnDisable() {
      if (
        !this.isLoggedIn &&
        this.cartHasAgeRestrictedItem &&
        this.businessConfigurations?.enableAgeRestrictedFulfillment &&
        !this.guestUser?.customerID
      ) {
        return !!!this.isDOBVerified
      } else return false
    },
  },
  methods: {
    openDatePicker() {
      if (this.$refs.qDateProxy) this.$refs.qDateProxy.show()
    },
    minTodayDate(d) {
      // return d >= this.formatDate(Date.now(), 'MM/DD/YYYY')
      return new Date().toISOString().slice(0, 10).split('-').join('/') >= d
    },
    selectDate() {
      this.personalInfo.dob = this.localDOB
      this.validateDOB(this.localDOB)
    },
    cancelDateSelection() {
      this.localDOB = this.personalInfo.dob
    },
    async onSubmit() {
      if (this.isLoggedIn) {
        if (this.isReviewOrder) {
          this.isViewMode = true
        } else {
          this.next()
        }
      } else {
        /* Verify guest age and continue */
        if (
          this.businessConfigurations?.isStoreAgeRestricted &&
          this.businessConfigurations?.enableAgeRestrictedCheckout &&
          this.isOrderVerified
        ) {
          if (!this.guestUser?.customerID) {
            try {
              this.loading = true
              let response = await this.$store.dispatch('cart/verifyGuestAge')
              if (response.success && response.data?.isVerified === true) {
                this.isGuestVerified = true
                this.personalInfoSubmitted = false
                // this.isGuestSelected = false
                this.$store.dispatch('guest/setGuestUser', {
                  guestUser: {
                    ...this.guestUser,
                    customerID: response.data.customerID,
                  },
                  setCustomerID: true,
                })
                this.fetchNextStepsAPI()
                this.loading = false
              }
            } catch (error) {
              console.log('error', error)
            }
          } else {
            if (this.isReviewOrder) {
              this.isViewMode = true
            } else {
              this.next()
            }
          }
        } else {
          this.$refs.personalInfoForm.validate().then(async (isValid) => {
            if (isValid) {
              if (!this.guestUser && !this.isPolicyAccepted) {
                this.isLegalAgreementUncheck = true
                this.loading = false
                return
              }

              if (this.personalInfoSubmitted && !this.isEmailFieldEdit) {
                this.saveEmailField()
              } else if (this.guestUser?.customerID) {
                this.fetchNextStepsAPI()
              } else if (!this.personalInfoSubmitted) {
                this.loading = true
                this.requestGuestOtp()
                  .then((response) => {
                    if (response.success && response.data?.token) {
                      this.tokenKey = response.data.token
                      this.personalInfoSubmitted = true
                      this.email = this.personalInfo.email
                      this.isEmailFieldEdit = true
                      this.startInterval()
                    } else if (
                      response.errors?.length &&
                      response.errors[0].errorCode == 'NO_GUEST_ALLOWED'
                    ) {
                      this.showError(response.errors[0].descreption, 5000)
                    }
                  })
                  .finally(() => (this.loading = false))
              } else if (this.isGuestVerified) {
                if (this.isReviewOrder) {
                  this.isViewMode = true
                } else {
                  this.next()
                }
              }
            }
            return
          })
        }
      }
    },
    cancelEdit() {
      if (this.isLoggedIn || this.guestUser != null) {
        this.isViewMode = true
      } else if (!this.isGuestVerified && this.personalInfoSubmitted) {
        this.personalInfoSubmitted = false
        this.isEmailFieldEdit = false
        this.resetForm()
        this.stopInterval()
      }
      // else if (this.isGuestSelected && !this.personalInfoSubmitted) {
      //   this.isGuestSelected = false
      //   this.resetForm()
      // }
    },
    editInfo() {
      this.isViewMode = false
    },
    toggleLegal() {
      this.isPolicyAccepted
        ? (this.isLegalAgreementUncheck = false)
        : (this.isLegalAgreementUncheck = true)
    },
    handleOnChange(value) {
      this.otp = value
    },
    resetForm() {
      this.personalInfo = {
        firstName: '',
        lastName: '',
        email: '',
      }
      this.isLegalAgreementUncheck = false
      this.isPolicyAccepted = false
      this.personalInfoSubmitted = false
      if (
        this.$refs.personalInfoForm &&
        this.$refs.personalInfoForm.resetValidation
      )
        this.$refs.personalInfoForm.resetValidation()
    },
    onChangeGuestSelection(val) {
      if (!val) {
        this.resetForm()
      }
    },
    saveEmailField() {
      if (this.personalInfoSubmitted && this.$refs.emailInput?.validate()) {
        if (this.email != this.personalInfo.email) {
          this.loadingForEmailField = true
          this.requestGuestOtp()
            .then((response) => {
              if (response.success && response.data?.token) {
                this.tokenKey = response.data.token
                this.email = this.personalInfo.email
                this.isEmailFieldEdit = !this.isEmailFieldEdit
                this.alertData.countDown = 0
                this.startInterval()
              } else if (
                response.errors?.length &&
                response.errors[0].errorCode == 'NO_GUEST_ALLOWED'
              ) {
                this.showError(response.errors[0].descreption, 5000)
              }
            })
            .finally(() => (this.loadingForEmailField = false))
        } else this.isEmailFieldEdit = !this.isEmailFieldEdit
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }

      this.resendSeconds = this.defaultResendTime

      this.interval = setInterval(() => {
        this.resendSeconds -= 1
        if (this.resendSeconds <= 0) clearInterval(this.interval)
      }, 1000)
    },
    stopInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
      this.resendSeconds = 0
    },
    resendCode() {
      if (this.tokenKey) {
        this.resending = true
        this.requestGuestOtp()
          .then((response) => {
            if (response.success && response.data?.token) {
              this.startInterval()
              this.tokenKey = response.data.token
            } else if (
              response.errors?.length &&
              response.errors[0].errorCode == 'NO_GUEST_ALLOWED'
            ) {
              this.showError(response.errors[0].descreption, 5000)
            }
          })
          .finally(() => (this.resending = false))
      }
    },
    formatOTPSeconds(seconds) {
      let time = new Date(seconds * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0]
      return time.split(':').splice(1, 2).join(':')
    },
    verifyOTP() {
      let slotID = ''
      if (
        this.checkSlotBookingRequired('scd') &&
        this.customerSCDSlotBookingID
      ) {
        slotID = this.customerSCDSlotBookingID
      } else if (
        this.checkSlotBookingRequired('sp') &&
        this.customerSPSlotBookingID
      ) {
        slotID = this.customerSPSlotBookingID
      }

      if (!this.otp || this.otp.length != this.otpLength) return
      this.verifying = true
      let requestObj = {
        ...this.personalInfo,
        otp: this.otp,
        token: this.tokenKey,
        orderNumber: this.cartNumber,
        slotBookingID: slotID,
      }
      this.$store
        .dispatch('cart/verifyGuestOtp', requestObj)
        .then((response) => {
          if (response.success && response.data?.isVerified === true) {
            this.isGuestVerified = true
            this.tokenKey = null
            this.email = null
            this.otp = ''
            this.personalInfoSubmitted = false
            // this.isGuestSelected = false
            this.stopInterval()

            if (response.data?.customerID)
              this.$store.dispatch(
                'cart/fetchGuestUserInfo',
                response.data.customerID
              )
          } else if (response.success === false && response.message) {
            this.alertData = { countDown: 10, message: response.message }
            setTimeout(() => {
              if (
                response.errors?.length &&
                response.errors[0]['errorCode'] == 'OTP_ATTEMPTS_EXCEEDED'
              ) {
                this.personalInfoSubmitted = false
                this.isEmailFieldEdit = false
                this.alertData = {
                  countDown: 0,
                  message: '',
                }

                this.stopInterval()
              } else if (
                response.errors?.length &&
                response.errors[0]['errorCode'] == 'NO_GUEST_ALLOWED'
              ) {
                this.showError(response.errors[0].descreption, 5000)
              }
            }, 1500)
          }
        })
        .finally(() => (this.verifying = false))
    },
    fetchNextStepsAPI() {
      try {
        const promises = [],
          reqData = {
            customerID: this.guestUser.customerID,
          }
        promises.push(
          this.$store.dispatch('cart/getCartDetail', {
            showFullLoader: true,
            isCheckoutPage: true,
          })
        )

        if (!Object.values(this.addresses).length) {
          promises.push(
            this.$store.dispatch('address/getCustomerAddresses', reqData)
          )
          promises.push(
            this.$store.dispatch('address/getBillingAddresses', reqData)
          )
        }
        if (!Object.values(this.paymentMethods).length) {
          promises.push(
            this.$store.dispatch('payment/getAvailableMethods', reqData)
          )
        }

        if (promises.length) {
          this.loading = true
          Promise.all(promises)
            .then((values) => {
              if (this.fulfillmentObject?.pickupPerson)
                this.$store.commit('cart/SET_PICKUP_INFO', {
                  fulfillmentMethodID:
                    this.fulfillmentObject.fulfillmentMethodID,
                  pickupPerson: this.fulfillmentObject.pickupPerson,
                })
              if (this.isReviewOrder) {
                this.isViewMode = true
              } else {
                this.next()
              }
            })
            .finally(() => (this.loading = false))
        } else {
          if (this.isReviewOrder) {
            this.isViewMode = true
          } else {
            this.next()
          }
        }
      } catch (error) {
        console.log('fetchNextStepsAPI error ', error)
      }
    },
    requestGuestOtp() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('cart/requestForGuestOtp', this.personalInfo)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    formatPhoneNumber(val) {
      return val ? formatPhoneNumber(val.replace(/ /g, '')) : val
    },
    validateDOB(date) {
      let years = this.calculateAgeByDate(new Date(date))
      if (years >= this.currentCartDetails.ageRequired) {
        this.isDOBVerified = true
      } else {
        this.isDOBVerified = false
      }
    },
    calculateAgeByDate(dateOfBirth, dateToCalculate = new Date()) {
      const dob = new Date(dateOfBirth).getTime(),
        dateToCompare = new Date(dateToCalculate).getTime(),
        age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000)
      return Math.floor(age)
    },
  },
}
</script>
<style lang="scss">
.cs-review-info {
  .q-field {
    .q-field__bottom {
      max-width: 125px;
    }
  }
}
</style>
