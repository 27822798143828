<template>
  <div>
    <div :class="isMobile ? 'checkoutHeightxs' : ''">
      <a
        href="javascript:void(0)"
        :class="['text-tertiary step-edit', { hidden: !isViewMode }]"
        @click="isViewMode = false"
      >
        <SvgIcon icon="edit" class="q-mr-sm" />Edit
      </a>
      <div class="row" v-show="!isViewMode">
        <div class="col-xs-12 col-md-12" v-show="isMobile">
          <h2 class="stepsTitle" v-html="title" />
        </div>
        <!-- <div class="col-xs-12 col-md-4 order-2" v-if="addressType == 'scd'">
          <div class="stepsTimeSlot" v-if="isSlotBookingRequired">
            <h5>
              Time Slot
              <a
                href="javascript:void(0);"
                class="text-tertiary"
                @click="openReserSlot('SCD')"
              >
                Edit
              </a>
            </h5>
            <p
              v-if="formatedCustomerSCDBookedSlot"
              v-html="formatedCustomerSCDBookedSlot"
            />
          </div>
        </div> -->
        <div
          :class="['col-xs-12', { 'col-md-12 order-md-1': addressType == 'scd' }]"
        >
          <div class="stepsAddress">
            <q-list>
              <q-item
                v-for="(address, index) in addressList"
                :key="`${address.addressId} ${index}`"
                tag="label"
                :class="
                  shippingAddress &&
                  shippingAddress.addressId == address.addressId &&
                  isAddressDisable(address)
                    ? 'active'
                    : ''
                "
              >
                <q-item-section
                  v-if="index == 'add-address'"
                  class="addAddress"
                  @click="editAddress()"
                >
                  <q-item-label>
                    <span>+</span>
                    Add new address
                  </q-item-label>
                </q-item-section>
                <q-item-section v-else>
                  <div
                    :style="
                      !isAddressDisable(address) && index !== 'add-address'
                        ? 'opacity: 0.3; cursor: not-allowed !important;'
                        : ''
                    "
                  >
                    <q-radio
                      size="sm"
                      :value="shippingAddressId"
                      :val="address.addressId"
                      :label="
                        shippingAddress &&
                        shippingAddress.addressId == address.addressId
                          ? 'Selected'
                          : 'Select'
                      "
                      @input="addressChanged(address)"
                      :disable="!isAddressDisable(address)"
                    />
                    <q-item-label>
                      {{ `${address.firstName} ${address.lastName}` }}
                      <span v-if="address.isDefault" class="default">
                        Default
                      </span>
                    </q-item-label>
                    <q-item-label
                      v-html="$options.filters.formatAddress(address)"
                      class="address q-mb-sm"
                    />
                    <q-item-label
                      v-if="addressType == 'scd' && address.isDeliver === false"
                      class="text-red bg-red-1 dotted-border-red delivery-address-note text-caption q-pa-sm rounded-borders q-mx-sm"
                    >
                      *Unable To Deliver to This Location
                    </q-item-label>
                    <q-item-label
                      v-else-if="
                        addressType == 'sd' &&
                        address.isShippingPossible === false
                      "
                      class="'text-red bg-red-1 dotted-border-red delivery-address-note text-caption q-pa-sm rounded-borders q-mx-sm'"
                    >
                      *Unable To ship to This Location
                    </q-item-label>
                  </div>
                  <!-- <q-btn
                  unelevated
                  round
                  dense
                  color="grey-6"
                  text-color="white"
                  padding="6px"
                  class="absolute-top-right q-mr-sm q-mt-sm"

                  ><q-icon name="edit" size="1rem"
                /></q-btn> -->
                  <a
                    href="javascript:void(0);"
                    class="editAddress"
                    @click="editAddress(address)"
                  >
                    <q-icon name="edit" size="1rem" />
                  </a>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>
      <div class="orderReviewInfo" v-show="isViewMode">
        <div class="row q-col-gutter-x-lg">
          <div class="col-xs-12 col-md-12" v-show="isMobile">
            <h2 class="stepsTitle">Address Details</h2>
          </div>
          <div
            class="col-xs-12 col-sm-6 col-md-4 q-mb-md order-md-2"
            v-if="addressType == 'scd' && isSlotBookingRequired"
          >
            <div class="stepsTimeSlot" v-if="isSlotBookingRequired">
              <h5>
                Time Slot
                <a
                  href="javascript:void(0);"
                  class="text-tertiary"
                  @click="openReserSlot('SCD')"
                >
                  Edit
                </a>
              </h5>
              <p
                v-if="formatedCustomerSCDBookedSlot"
                v-html="formatedCustomerSCDBookedSlot"
              />
            </div>
          </div>

          <div
            class="col-xs-12 col-sm-6 col-md-4 q-mb-md order-md-1"
            v-if="selectedAddress"
          >
            <h5
              v-text="
                addressType && addressType === 'scd'
                  ? 'Delivery Address'
                  : 'Shipping to'
              "
            ></h5>
            <p>
              {{ selectedAddress.firstName + ' ' + selectedAddress.lastName }}
              <span v-if="selectedAddress.isDefault" class="default">
                Default
              </span>
            </p>
            <p v-html="$options.filters.formatAddress(selectedAddress)" />
            <q-item-label
              v-if="addressType == 'scd' && selectedAddress.isDeliver === false"
              class="text-red delivery-address-note q-pt-sm"
            >
              *Unable To Deliver to This Location
            </q-item-label>
            <q-item-label
              v-else-if="
                addressType == 'sd' &&
                selectedAddress.isShippingPossible === false
              "
              class="text-red delivery-address-note q-pt-sm"
            >
              *Unable To ship to This Location
            </q-item-label>
          </div>
        </div>
      </div>
    </div>
    <StepperNavigationBtn
      v-show="!isViewMode"
      :next="applyOrContinue"
      :label="stepperNavigationBtnLabel"
      :loading="loading"
      :disable="!shippingAddress"
      :isReviewOrder="isReviewOrder"
      :cancelAction="cancelEdit"
    />
  </div>
</template>

<script>
import StepperNavigationBtn from './StepperNavigationBtn'
import { mapGetters } from 'vuex'
import {
  mergeObjects,
  convertArrayToObject,
  hideShowFullSpinner,
} from 'src/utils'

export default {
  name: 'OrderDetails',
  components: { StepperNavigationBtn },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    addressType: {
      type: String,
      default: 'sd',
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isViewMode: this.isReviewOrder,
      loading: false,
      currentAddress: null,
    }
  },
  computed: {
    ...mapGetters('address', ['addresses']),
    ...mapGetters('cart', [
      'currentCartNumber',
      'addressByFulfillmentId',
      'currentCartDetails',
    ]),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    ...mapGetters('persistedLocal', [
      'getCartPersistedInfo',
      'selectedAddressForFulfillmentCode',
    ]),
    ...mapGetters('common', [
      'isSlotBookingRequired',
      'checkSlotBookingRequired',
    ]),
    // ...mapGetters('reserveSlot', ['formatedCustomerSCDBookedSlot']),
    ...mapGetters('reserveSlot', ['formatBookedSlot']),
    ...mapGetters('common', ['isSlotBookingRequired']),
    formatedCustomerSCDBookedSlot() {
      return this.formatBookedSlot('scd')
    },
    addressList() {
      let addressList = { ...this.addresses }
      if (this.selectedFulfillmentCodeName != 'sp') {
        let key =
          this.selectedFulfillmentCodeName == 'scd'
            ? 'isDeliver'
            : 'isShippingPossible'
        let sorted = Object.values(addressList).sort(function (a, b) {
          return b[key] - a[key]
        })
        addressList = convertArrayToObject(sorted, 'addressId')
      }
      if (this.isMobile) return mergeObjects({ 'add-address': {} }, addressList)
      return mergeObjects(addressList, { 'add-address': {} })
    },
    selectedAddress() {
      return this.selectedAddressForFulfillmentCode(this.addressType)
    },
    fulfillmentId() {
      let fulfillment = this.businessFulfillments[this.addressType]

      return fulfillment?.fulfillmentID || fulfillment?.fulfillmentId || ''
    },
    fulfillmentDetails() {
      let fulfillment = this.businessFulfillments[this.addressType]

      return fulfillment || ''
    },
    shippingAddress: {
      get() {
        return this.currentAddress ? this.currentAddress : this.selectedAddress
      },
      set(address) {
        this.currentAddress = address
      },
    },
    shippingAddressId() {
      return (this.shippingAddress && this.shippingAddress.addressId) || null
    },
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : ''
    },
    persistedShippingInfo() {
      return this.getCartPersistedInfo(this.currentCartNumber)
    },
  },
  methods: {
    isAddressDisable(address) {
      let key =
        this.selectedFulfillmentCodeName == 'scd'
          ? 'isDeliver'
          : 'isShippingPossible'
      return address.hasOwnProperty(key) && address[key] ? address[key] : false
    },
    openReserSlot(bookingType) {
      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: true,
        properties: {
          activeTab: bookingType,
        },
      })
    },
    editAddress(address = {}) {
      address = { ...address, fulfillmentId: this.fulfillmentId }
      let properties = { address }
      if (this.currentCartDetails?.customerID)
        properties.customerID = this.currentCartDetails.customerID
      this.changeDialogState({
        dialog: 'addShippingAddress',
        val: true,
        properties,
      })
    },
    applyOrContinue() {
      // if (
      //   this.selectedAddress &&
      //   this.shippingAddress.addressId == this.selectedAddress.addressId
      // ) {
      //   if (this.isReviewOrder) {
      //     this.isViewMode = true
      //   } else {
      //     return this.next()
      //   }
      // }

      let params = {
        OrderNumer: this.currentCartNumber,
        fulfillmentMethodID: this.fulfillmentId,
        addressID: this.shippingAddress.addressId,
      }
      if (this.currentCartDetails?.customerID)
        params.customerID = this.currentCartDetails.customerID
      this.loading = true

      this.$store
        .dispatch('cart/updateCartAddress', params)
        .then((response) => {
          if (response?.success) {
            // this.$store.commit('persistedLocal/SET_SELECTED_ADDRESS', {
            //   address: this.shippingAddress,
            //   fulfillmentCode: this.addressType,
            // })
            this.loading = false
            if (this.isReviewOrder) {
              this.isViewMode = true
            } else {
              this.next()
            }
          } else {
            this.loading = false
            if (response?.errors?.length) {
              this.$root.$emit('scroll-to-position', 0)
              this.$store.dispatch('cart/showAlert', response.errors)
            }
          }

          // Calculate Delivery Rates and disable checkout when any error occurs
          if (this.selectedFulfillmentCodeName == 'scd') {
            hideShowFullSpinner(true)
            this.$store
              .dispatch('cart/getDeliveryRates')
              .then((response) => {
                let isAllowed = !(response && response?.errors?.length)
                this.$store.commit('cart/SET_ALLOW_PLACE_ORDER', isAllowed)
                if (response && response?.errors?.length) {
                  this.$root.$emit('scroll-to-position', 0)
                  this.$store.dispatch('cart/showAlert', response.errors)
                }
                hideShowFullSpinner(false)
              })
              .catch(() => hideShowFullSpinner(false))
          }
          // we are going to update ShippingRates based on address selection
          else if (this.selectedFulfillmentCodeName == 'sd') {
            let cartObj = {
              cartNumber: this.currentCartNumber,
            }
            if (this.persistedShippingInfo?.deliveryInstruction)
              cartObj.data = {
                deliveryInstruction:
                  this.persistedShippingInfo.deliveryInstruction,
              }
            this.$store.commit(
              'persistedLocal/SET_CART_PERSISTED_INFO',
              cartObj
            )

            this.$store.dispatch('cart/getShippingRates', true)
          }
        })
    },
    cancelEdit() {
      this.currentAddress = this.selectedAddress
      this.isViewMode = true
    },
    addressChanged(address) {
      this.shippingAddress = address
    },
  },
  created() {
    this.$root.$on('address-added-or-modified', async (data) => {
      if (
        data &&
        data.fulfillmentId &&
        this.fulfillmentId === data.fulfillmentId &&
        data.address.addressId
      ) {
        this.shippingAddress = this.addresses[data.address.addressId]
      }
    })
  },
}
</script>
<style lang="scss">
.delivery-address-note {
  .searchAddressList {
    .address {
      padding-bottom: 30px !important;
    }
    .delivery-address-note {
      font-size: 12px !important;
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 70px;
      text-align: center;
    }
  }
  .reserveTimeSlot
    .scheduleList
    .searchAddress
    .searchAddressList
    .q-item
    .q-item__section
    .q-item__label.delivery-address-note {
    line-height: 13px !important;
  }
}
</style>
